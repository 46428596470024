import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa6";

const SaveButton = ({ onClick }) => {
  return (
    <Button
      className="d-flex action-buttons align-items-center mb-3 mt-3"
      onClick={onClick}
    >
      <FaDownload className="me-2" /> Save
    </Button>
  );
};

export default SaveButton;
