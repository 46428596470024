import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import UserNotes from "./UserNotes";
import ResultCard from "../../reusable-components/ResultCard";
import SaveButton from "../../reusable-components/SaveButton";
import useToast from "../../reusable-components/UseToast";
import useAxios from "../../utils/UseAxios";
import LanguageSelector from "../../reusable-components/LanguageSelector";
import { Configuration, OpenAIApi } from "openai";
import * as Sentry from "@sentry/react";
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";

const ClassNotes = () => {
  const { user } = useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();
  const axiosInstance = useAxios();

  const [title, setTitle] = useState("");
  const [bulletPoints, setBulletPoints] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [titlePlaceholder, setTitlePlaceholder] = useState("Biology - Cell Structure and Functions");
  const [fadeClass, setFadeClass] = useState("");
  const [language, setLanguage] = useState("en"); 

  const titlePlaceholders = [
    "Biology - Cell Structure and Functions",
    "Physics - Laws of Motion",
    "History - World War II",
    "Literature - Shakespeare's Works",
  ];

  const placeholderMap = {
    "Biology - Cell Structure and Functions": [
      "Introduction to cell structure",
      "Cell membrane structure",
      "Nucleus and its functions",
      "Mitochondria and energy production",
      "Conclusion on cell structure",
      "Cell organelles",
      "Cell communication",
      "DNA replication",
      "Cell cycle and division",
      "Apoptosis",
    ],
    "Physics - Laws of Motion": [
      "Introduction to Newton's laws",
      "First law of motion",
      "Second law of motion",
      "Third law of motion",
      "Conclusion on laws of motion",
      "Applications of Newton's laws",
      "Gravitational force",
      "Centripetal force",
      "Momentum conservation",
      "Friction and its effects",
    ],
    "History - World War II": [
      "Causes of World War II",
      "Major battles",
      "Impact on the world",
      "End of the war",
      "Post-war consequences",
      "Allied powers",
      "Axis powers",
      "Nuclear warfare",
      "Holocaust",
      "War trials",
    ],
    "Literature - Shakespeare's Works": [
      "Introduction to Shakespeare",
      "Key themes",
      "Major plays",
      "Shakespeare's influence",
      "Conclusion on Shakespeare",
      "Shakespearean language",
      "Character analysis",
      "Literary devices",
      "Historical context",
      "Shakespeare's sonnets",
    ],
  };

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        const newTitle = titlePlaceholders[currentIndex % titlePlaceholders.length];
        setTitlePlaceholder(newTitle);
        const placeholders = placeholderMap[newTitle];
        setBulletPoints((prevBulletPoints) => {
          return prevBulletPoints.map((point, index) => (point === "" ? "" : point));
        });
        setFadeClass("fade-in");
        setTimeout(() => {
          setFadeClass("");
        }, 200);
        currentIndex = (currentIndex + 1) % titlePlaceholders.length;
      }, 300);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const addBulletPoint = () => {
    if (bulletPoints.length < 10) {
      setBulletPoints([...bulletPoints, ""]);
    } else {
      toast.error("You can add up to 10 bullet points.");
    }
  };

  const removeBulletPoint = (index) => {
    setBulletPoints(bulletPoints.filter((_, i) => i !== index));
  };

  const handleBulletPointChange = (index, value) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints[index] = value;
    setBulletPoints(newBulletPoints);
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    console.log("Selected language in dropdown:", selectedLanguage);
    setLanguage(selectedLanguage);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const notesContent = bulletPoints.filter((point) => point.trim() !== "").join("\n- ");

    setIsLoading(true);
    console.log('Selected language:', language);
    console.log('Topic:', title || titlePlaceholder);

    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);

    try {
      const apiResponse = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: `You are a helpful assistant that always responds in ${language}.`,
          },
          {
            role: "user",
            content: `The student has provided the following class notes:
                -------
                - ${notesContent}
                -------
                Topic: ${title || titlePlaceholder}
                Provide additional useful summary information with exactly five sentences for each bullet point and recommend at least one book for further study. Reply in ${language}.`,
          },
        ],
      });

      setResponse(apiResponse.data.choices[0].message.content);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("An error occurred while generating notes. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFile = async () => {
    try {
      const saveResponse = await axiosInstance.post("/cases/files/", {
        name: title || titlePlaceholder,
        content: response,
        user: user.user_id,
      });
      toast.success("Note saved successfully");
      navigate(`/note/${saveResponse.data.id}`);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to save note");
    }
  };

  return (
    <Container className="app-container pt-4">
      <Breadcrumb
        items={[
          { label: "Home", path: "/", active: false },
          { label: "Note", path: "/note", active: true },
        ]}
      />
      <h3 style={{ textAlign: "left", paddingLeft: "20px" }}>
        Interactive Class Notes Generator
      </h3>
      <p
        style={{ textAlign: "left", paddingLeft: "20px", paddingBottom: "20px" }}
      >
        Keep detailed notes on courses covering economics to world geography,
        helping you remember concepts from supply and demand to the seven
        continents.
      </p>
      <Row>
        <Col md={12}>
          <Form style={{ paddingLeft: "20px" }} onSubmit={onFormSubmit}>
            <FormGroup className="d-flex align-items-center">
              <FormLabel
                className="app-form-label me-2"
                style={{ width: "120px", fontWeight: "bold" }}
              >
                Language
              </FormLabel>
              <LanguageSelector
                  onLanguageChange={handleLanguageChange}
                  className="dropdown-select"
                />
            </FormGroup>

            <FormGroup className="d-flex align-items-center">
              <FormLabel
                className="app-form-label me-2"
                style={{ width: "120px", fontWeight: "bold" }}
              >
                Title
              </FormLabel>
              <FormControl
                type="text"
                name="title"
                placeholder={titlePlaceholder}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setBulletPoints([""]);
                }}
                required
                className={`app-input ${fadeClass}`}
                style={{ color: "#6c757d" }}
              />
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <FormLabel
                className="app-form-label me-2"
                style={{ width: "120px", fontWeight: "bold" }}
              >
                Bullet Points
              </FormLabel>
              <div className="flex-grow-1 w-100">
                {bulletPoints.map((bulletPoint, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <FormControl
                      type="text"
                      value={bulletPoint}
                      onChange={(e) =>
                        handleBulletPointChange(index, e.target.value)
                      }
                      placeholder={
                        bulletPoint === ""
                          ? placeholderMap[titlePlaceholder][index]
                          : ""
                      }
                      className={`app-input ${fadeClass}`}
                      style={{
                        color:
                          bulletPoint === "" ? "#6c757d" : "inherit", // Placeholder color if empty, otherwise normal text color
                      }}
                    />
                    {bulletPoints.length > 1 && (
                      <Button
                        variant="danger ml-2 remove-bulletpoint"
                        onClick={() => removeBulletPoint(index)}
                      >
                        -
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </FormGroup>
            <Button
              className="d-flex action-buttons mt-2"
              onClick={addBulletPoint}
            >
              + Add Bullet Point
            </Button>
            <Container
              className="submit-container"
              style={{ textAlign: "left", paddingLeft: "120px" }}
            >
              <Button
                className="submit-button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Generating..." : "Submit"}
              </Button>
            </Container>
          </Form>

          {response && (
            <ResultCard heading={`Improved notes for: ${title || titlePlaceholder}`} content={response} />
          )}

          {response && (
            <Container className="sticky-buttons">
              <SaveButton onClick={handleSaveFile} />
              <DownloadFile content={response} fileName={`${title || titlePlaceholder}.pdf`} />
              <ListenButton text={response} />
            </Container>
          )}
        </Col>
      </Row>
      <Row className="latest-notes mt-5" style={{ textAlign: "left" }}>
        <Col className="d-flex flex-column align-items-start">
          <UserNotes displayAll={false} />
        </Col>
      </Row>
    </Container>
  );
};

export default ClassNotes;
