import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link, useNavigate } from "react-router-dom";
import { IoEnterOutline } from "react-icons/io5";

const UserEssays = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [essays, setEssays] = useState([]);
  const [isFetched, setIsFetched] = useState(false); 
    const axiosInstance = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEssays = async () => {
      try {
        const response = await axiosInstance.get(`/cases/essays/?user_id=${user.user_id}`);
        setEssays(response.data);
        setIsFetched(true); 
      } catch (error) {
        console.error("Error fetching essays:", error);
      }
    };
  
    if (user && !isFetched) {
      fetchEssays();
    }
  }, [user, axiosInstance, isFetched]); 

  const displayEssays = displayAll ? essays : essays.slice(0, 5);

  const renderGrid = (essay) => (
    <Col key={essay.id} sm={12} md={6} lg={4} className="my-2 latest-feature-item">
      <Card>
        <Card.Body>
          <Link to={`/essay/${essay.id}`} className="card-title">
            {essay.title}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderList = (essay) => (
    <Card key={essay.id} className="my-2 latest-feature-item">
      <Card.Body>
        <Link to={`/essay/${essay.id}`} className="card-title">
          {essay.title}
        </Link>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="latest-feature-container">
      <h2>{displayAll ? "All Essays" : "Latest Essays"}</h2>
      {displayAll ? (
        <Row>{displayEssays.map(renderGrid)}</Row>
      ) : (
        displayEssays.map(renderList)
      )}
      {!displayAll && (
        <Container className="all-items-link mt-4 mb-5 go-container latest-feature-item" onClick={() => navigate("/essays")} style={{ cursor: 'pointer' }}>
          All Essays <IoEnterOutline size={24} /> 
        </Container>
      )}
    </Container>
  );
};

export default UserEssays;
