import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Image } from 'react-bootstrap';
import { FaUserAstronaut } from "react-icons/fa6";

const Navigation = () => {
  const logoLight = "https://i.ibb.co/C7ykCPj/logo-white.png";

  return (
    <div className="navigation light-theme">
      <Navbar sticky="top" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center' }}>
          <Image className="logo" loading="lazy" src={logoLight} style={{ height: '100px', width: 'auto' }} />
        </Navbar.Brand>
        <a className='profile-icon' href='/profile' style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <FaUserAstronaut style={{ fontSize: '2.5rem' }} />
        </a>
      </Navbar>
    </div>
  );
};

export default Navigation;
