import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Col, Row, Button, FormGroup, FormLabel, FormControl, Form } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { Configuration, OpenAIApi } from "openai";
import AuthContext from "../../utils/AuthContext";
import LoginPage from "../../authentication/LoginPage";
import useAxios from "../../utils/UseAxios";
import QuizCard from "./QuizCard";
import UserQuizzes from "./UserQuizzes";
import LanguageSelector from "../../reusable-components/LanguageSelector";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import DropdownSelect from '../../reusable-components/DropdownSelect';
import Footer from "../../reusable-components/Footer";

const QuizApp = () => {
    const { user, authTokens } = useContext(AuthContext);
    const loggedIn = user !== null;
    const axiosInstance = useAxios();
    const [isLoading, setIsLoading] = useState(false);
    const [heading, setHeading] = useState("");
    const [quizData, setQuizData] = useState(null);
    const [formDataObj, setFormDataObj] = useState({});
    const [selectedFile, setSelectedFile] = useState();
    const [choice, setChoice] = useState("General Topic");
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
    const [incorrectAnswersCount, setIncorrectAnswersCount] = useState(0);
    const [focus, setFocus] = useState("");
    const [topicPlaceholder, setTopicPlaceholder] = useState("History");
    const [focusPlaceholder, setFocusPlaceholder] = useState("American Civil War");
    const [fadeClass, setFadeClass] = useState("");

    const generalTopicPlaceholders = [
        { topic: "History", focus: "American Civil War" },
        { topic: "Mathematics", focus: "Calculus" },
        { topic: "Science", focus: "Periodic Table" },
        { topic: "Geography", focus: "World Capitals" }
    ];

    const specificBookPlaceholders = [
        { topic: "To Kill a Mockingbird by Harper Lee", focus: "Racial Injustice" },
        { topic: "1984 by George Orwell", focus: "Totalitarianism" },
        { topic: "Pride and Prejudice by Jane Austen", focus: "Marriage and Society" },
        { topic: "Moby Dick by Herman Melville", focus: "Obsession and Revenge" }
    ];

    useEffect(() => {
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            setFadeClass("fade-out");
            setTimeout(() => {
                if (choice === "General Topic") {
                    setTopicPlaceholder(generalTopicPlaceholders[currentIndex].topic);
                    setFocusPlaceholder(generalTopicPlaceholders[currentIndex].focus);
                } else if (choice === "Specific Book") {
                    setTopicPlaceholder(specificBookPlaceholders[currentIndex].topic);
                    setFocusPlaceholder(specificBookPlaceholders[currentIndex].focus);
                }
                setFadeClass("fade-in");
                setTimeout(() => {
                    setFadeClass("");
                }, 200);
                currentIndex = (currentIndex + 1) % (choice === "General Topic" ? generalTopicPlaceholders.length : specificBookPlaceholders.length);
            }, 300);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [choice]);

    const resetQuiz = () => {
        setHeading("");
        setQuizData(null);
        setFormDataObj({});
        setCorrectAnswersCount(0);
        setIncorrectAnswersCount(0);
    };

    const handleChoiceChange = (e) => {
        resetQuiz();
        setChoice(e.target.value);
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setFormDataObj(prev => ({ ...prev, fileContent: e.target.files[0] }));
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const generateQuiz = async (formDataObj) => {
        const configuration = new Configuration({
            apiKey: "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",
        });
        const openai = new OpenAIApi(configuration);
        
        let promptMessage = `Generate a quiz with 10 questions, each with 5 options, on the topic "${formDataObj.topic}". The focus should be on "${formDataObj.focus}". The quiz should be in ${formDataObj.language}. Provide the questions first, followed by the phrase "And here are the answers:", then list the correct answers in a format like ["a", "b", "c", "d", "e", ...]. Each option should start with the corresponding letter without any leading symbols or extra spaces`;

        try {
            const response = await openai.createChatCompletion({
                model: "gpt-4o-mini",
                messages: [
                    { role: "system", content: "You are a helpful assistant." },
                    { role: "user", content: promptMessage },
                ],
            });

            const rawResponse = response.data.choices[0].message.content;
            const splitResponse = rawResponse.split('And here are the answers:');
            const questionsText = splitResponse[0];
            let answersText;

            if (splitResponse.length > 1) {
                answersText = splitResponse[1].trim();
            } else {
                throw new Error('Expected "And here are the answers:" in response, but it was not found.');
            }

            let correctAnswers;
            if (answersText.startsWith('[') && answersText.endsWith(']')) {
                answersText = answersText.replace(/'/g, '"');
                correctAnswers = JSON.parse(answersText);
            } else {
                correctAnswers = answersText.split("\n").map(line => line.trim().split(' ')[1].slice(0, -1));
            }

            const rawQuestionsAndAnswers = questionsText.split(/(?<=\n)\d+\./)
                .slice(1)
                .map(question => question.trim())
                .filter(Boolean);

            const questions = rawQuestionsAndAnswers.map((rawQuestionAndAnswer, i) => {
                const lines = rawQuestionAndAnswer.split("\n");
                const text = lines[0].replace(/^\d+\.\s*/, "");
                const options = lines.slice(1);

                const validAnswers = ["a", "b", "c", "d", "e"];
                if (!validAnswers.includes(correctAnswers[i])) {
                    throw new Error(`Invalid correct answer provided for question ${i + 1}: ${correctAnswers[i]}`);
                }

                const correctOption = correctAnswers[i];
                return { text, correctOption, options };
            });

            if (questions.length !== correctAnswers.length) {
                throw new Error('Mismatch between number of questions and answers.');
            }

            setHeading(formDataObj.topic);
            setQuizData(questions);
            setIsLoading(false);

        } catch (error) {
            Sentry.captureException(error);
            toast.error('Failed to generate quiz. Please try again.');
            setIsLoading(false);
        }
    };

    const handleFileUpload = async () => {
        const file = formDataObj.fileContent;
  
        if (!file) {
          toast.error("No file selected");
          return;
        }
  
        if (file.size > 5 * 1024 * 1024) {
          toast.error("Maximum file size for upload should be less than 5MB");
          return;
        }
  
        setIsLoading(true);  
        const formData = new FormData();
        formData.append("uploaded_file", file);
  
        try {
          const response = await axiosInstance.post("/file_processing/upload/", formData, {
            headers: {
              'Authorization': `Bearer ${authTokens.access}`,
              'Content-Type': 'multipart/form-data'
            }
          });
  
          const extractedText = response.data.file_content;
          const fileFormDataObj = { fileContent: extractedText, language: formDataObj.language };

          const configuration = new Configuration({
              apiKey: "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",
          });
          const openai = new OpenAIApi(configuration);
  
          const promptMessage = `Generate a quiz with 10 questions, each with 5 options, based on the content: "${extractedText}". The quiz should be in ${formDataObj.language}. Avoid questions about author publisher etc and in "${extractedText}" focus on table of content and provide question based on what you know about those topics there. Provide the questions first, followed by the phrase "And here are the answers:", then list the correct answers in a format like ["a", "b", "c", "d", "e", ...]. Each option should start with the corresponding letter without any leading symbols or extra spaces`;
          
          const aiResponse = await openai.createChatCompletion({
              model: "gpt-4o-mini",
              messages: [
                  { role: "system", content: "You are a helpful assistant." },
                  { role: "user", content: promptMessage },
              ],
          });

          const rawResponse = aiResponse.data.choices[0].message.content;
          const splitResponse = rawResponse.split('And here are the answers:');
          const questionsText = splitResponse[0];
          let answersText;

          if (splitResponse.length > 1) {
              answersText = splitResponse[1].trim();
          } else {
              throw new Error('Expected "And here are the answers:" in response, but it was not found.');
          }

          let correctAnswers;
          if (answersText.startsWith('[') && answersText.endsWith(']')) {
              answersText = answersText.replace(/'/g, '"');
              correctAnswers = JSON.parse(answersText);
          } else {
              correctAnswers = answersText.split("\n").map(line => line.trim().split(' ')[1].slice(0, -1));
          }

          const rawQuestionsAndAnswers = questionsText.split(/(?<=\n)\d+\./)
              .slice(1)
              .map(question => question.trim())
              .filter(Boolean);

          const questions = rawQuestionsAndAnswers.map((rawQuestionAndAnswer, i) => {
              const lines = rawQuestionAndAnswer.split("\n");
              const text = lines[0].replace(/^\d+\.\s*/, "");
              const options = lines.slice(1);

              const validAnswers = ["a", "b", "c", "d", "e"];
              if (!validAnswers.includes(correctAnswers[i])) {
                  throw new Error(`Invalid correct answer provided for question ${i + 1}: ${correctAnswers[i]}`);
              }

              const correctOption = correctAnswers[i];
              return { text, correctOption, options };
          });

          if (questions.length !== correctAnswers.length) {
              throw new Error('Mismatch between number of questions and answers.');
          }

          const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
          setHeading(fileNameWithoutExtension);
          setQuizData(questions);
          setIsLoading(false);
  
        } catch (error) {
          Sentry.captureException(error);
          toast.error("Failed to process file. Please upload book or clear image");
        } finally {
          setIsLoading(false);  
        }
    };
  
    const onFormSubmit = async (e) => {
        e.preventDefault();
        
        if (choice === "Upload File") {
            handleFileUpload();
            return;
        }
    
        const formData = new FormData(e.target);
        const localFormDataObj = Object.fromEntries(formData.entries());
    
        setFormDataObj(localFormDataObj);
        setIsLoading(true);
    
        try {
            await generateQuiz(localFormDataObj);
        } catch (error) {
            Sentry.captureException(error);
            toast.error("An error occurred while generating the quiz. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    if (!loggedIn) {
        return <LoginPage />;
    }

    return (
        <>
            <Container className="app-container pt-4">
                <Breadcrumb items={[
                    { label: 'Home', path: '/', active: false },
                    { label: 'Quiz', path: '/quiz', active: true }
                ]} />
                <h3 style={{ textAlign: "left", paddingLeft: "20px" }}>Interactive Quiz Generator</h3>
                <p style={{ textAlign: "left", paddingLeft: "20px", paddingBottom: "20px" }}>
                    Create quizzes on topics ranging from Indian mythology to global history, 
                    testing knowledge on everything from the Vedas to the adventures of Joan of Arc.
                </p>
                <Row>
                    <Col md={12}>
                        <Form style={{ paddingLeft: "20px" }} onSubmit={onFormSubmit}>
                            <FormGroup className="d-flex align-items-center">
                                <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Options</FormLabel>
                                <DropdownSelect
                                    name="choice"
                                    options={[
                                        { label: 'General Topic', value: 'General Topic' },
                                        { label: 'Specific Book', value: 'Specific Book' },
                                        { label: 'Upload File', value: 'Upload File' }
                                    ]}
                                    value={choice}
                                    onChange={handleChoiceChange}
                                    className="dropdown-select"
                                />
                            </FormGroup>

                            <FormGroup className="d-flex align-items-center">
                                <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Language</FormLabel>
                                <LanguageSelector onLanguageChange={(e) => setFormDataObj(prevState => ({ ...prevState, language: e.target.value }))} className="dropdown-select" />
                            </FormGroup>

                            <FormGroup className="d-flex align-items-center">
                                <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Difficulty</FormLabel>
                                <DropdownSelect
                                    name="difficulty"
                                    options={[
                                        { label: 'Easy', value: 'Easy' },
                                        { label: 'Medium', value: 'Medium' },
                                        { label: 'Hard', value: 'Hard' }
                                    ]}
                                    value={formDataObj?.difficulty || 'Easy'}
                                    onChange={(e) => {
                                        setFormDataObj(prevState => ({ ...prevState, difficulty: e.target.value }));
                                    }}
                                    className="dropdown-select"
                                />
                            </FormGroup>

                            {choice !== "Upload File" && (
                                <>
                                    <FormGroup className="d-flex align-items-center">
                                        <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Subject</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="topic"
                                            placeholder={topicPlaceholder}
                                            required
                                            className={`app-input ${fadeClass}`}
                                        />
                                    </FormGroup>

                                    <FormGroup className="d-flex align-items-center">
                                        <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Focus</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="focus"
                                            placeholder={focusPlaceholder}
                                            value={focus}
                                            onChange={(e) => setFocus(e.target.value)}
                                            className={`app-input ${fadeClass}`}
                                        />
                                    </FormGroup>
                                </>
                            )}

                            {choice === "Upload File" && (
                                <FormGroup className="d-flex align-items-center">
                                    <FormLabel className="app-form-label me-2" style={{ width: '120px' }}>Upload File</FormLabel>
                                    <FormControl 
                                        type="file" 
                                        onChange={handleFileChange} 
                                        required 
                                        className="app-input"
                                    />
                                </FormGroup>
                            )}

                            <Container className="submit-container" style={{ display: 'flex', alignItems: 'center' }}>
                                <Button className="submit-button mt-3" type="submit" disabled={isLoading}>
                                    {isLoading ? 'Generating...' : 'Submit'}
                                </Button>
                            </Container>
                        </Form>
                    </Col>
                </Row>

                {quizData && (
                    <>
                        <QuizCard 
                            data={quizData} 
                            setCorrectAnswersCount={setCorrectAnswersCount} 
                            setIncorrectAnswersCount={setIncorrectAnswersCount}
                            quizTitle={heading}
                        />
                        <Button className="mt-3 action-buttons w-100" onClick={handleRefresh}>Create New Quiz</Button>
                    </>
                )}
                
                <Container className={`latest-quizzes ${quizData ? "mt-5" : ""}`}>
                    <UserQuizzes displayAll={false} />
                </Container>
                
            </Container>
            {quizData && <Footer />}
        </>
    );
};

export default QuizApp;
