import { toast } from "react-toastify";

const useToast = () => {
  const success = (message) => {
    toast.success(message);
  };

  const error = (message) => {
    toast.error(message);
  };

  return { success, error };
};

export default useToast;
