import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import LoadingSpinner from "../../reusable-components/LoadingSpinner";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import EditButton from "../../reusable-components/EditButton";
import SaveButton from "../../reusable-components/SaveButton";

const ExamDetail = () => {
  const [exam, setExam] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedExam, setEditedExam] = useState({});
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status


  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await axiosInstance.get(`/cases/exams/${id}/`);
        if (response.status === 403) {
          navigate("/exams");
        } else {
          setExam(response.data);
          setIsFetched(true); // Set to true after exam is fetched
        }
      } catch (error) {
        console.error("Error fetching exam:", error);
        if (error.response && error.response.status === 403) {
          navigate("/exams");
        } else {
          navigate("/exams");
        }
      }
    };
  
    // Only fetch exam if it hasn't been fetched yet
    if (!isFetched) {
      fetchExam();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const handleEditClick = () => {
    setEditing(true);
    setEditedExam(exam);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedExam((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/cases/exams/${id}/`, editedExam);
      setExam(editedExam);
      setEditing(false);
      setIsFetched(false); // Reset to allow refetching if needed
      toast.success("Exam updated successfully");
    } catch (error) {
      toast.error("Failed to update exam");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/exams/${id}/`);
      setDeleted(true);
      toast.success("Exam deleted successfully!");
      navigate("/exams");
    } catch (error) {
      console.error("Error deleting exam:", error);
      toast.error("Failed to delete exam");
    }
  };

  if (deleted) {
    return null;
  }

  if (!exam) {
    return <LoadingSpinner />;
  }

  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Exam', path: '/exam', active: false },
    { label: 'Exams', path: '/exams', active: false },
    { label: exam?.book || 'Exam Details', path: `/exam/${id}`, active: true },
  ];

  const renderQuestions = () => {
    try {
      const questions = JSON.parse(exam.content);
      return (
        <div className="question-container">
          <ul className="question-list">
            {questions.map((item, index) => (
              <li key={index} className="question-item">
                {item.question}
              </li>
            ))}
          </ul>
        </div>
      );
    } catch (e) {
      return <div className="error-message">Failed to parse exam questions.</div>;
    }
  };

  return (
    <Container className="left-align">
      <Breadcrumb items={breadcrumbItems} />
      <Form className="pt-3 single-item-form">
        <Form.Group>
          {editing ? (
            <Form.Control
              name="book"
              type="text"
              value={editedExam.book}
              onChange={handleInputChange}
            />
          ) : (
            <h2 className="pb-3">{exam.book}</h2>
          )}
        </Form.Group>
        <Form.Group>
          {editing ? (
            <ReactQuill
              name="content"
              value={editedExam.content}
              onChange={(value) =>
                setEditedExam((prevState) => ({
                  ...prevState,
                  content: value,
                }))
              }
            />
          ) : (
            <div className="content-wrapper">
              {renderQuestions()}
            </div>
          )}
        </Form.Group>
        {editing ? (
          <Container className="sticky-buttons">
            <SaveButton onClick={handleSaveClick} />
            <CancelButton onClick={() => setEditing(false)} />
          </Container>
        ) : (
          <Container className="sticky-buttons">
            <DownloadFile content={exam.content} fileName={`Exam.pdf`} />
            {/* <EditButton onClick={handleEditClick} /> */}
            <ListenButton text={exam.content} />
            <DeleteButton onClick={handleDeleteClick} />
          </Container>
        )}
      </Form>
    </Container>
  );
};

export default ExamDetail;
