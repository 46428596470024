import React, { useState, useEffect, useContext } from 'react';
import { toast } from "react-toastify";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import useAxios from "../../utils/UseAxios";
import SaveButton from "../../reusable-components/SaveButton";
import ListenButton from "../../reusable-components/ListenButton";
import AuthContext from "../../utils/AuthContext";  

const QuizCard = ({ data, setCorrectAnswersCount, setIncorrectAnswersCount, quizTitle }) => {
    const { user } = useContext(AuthContext); 
    const [selectedOption, setSelectedOption] = useState(null);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const axiosInstance = useAxios();

    const checkAnswer = (selectedOptionLetter, correctOptionLetter, questionIndex) => {
        // Normalize both selected and correct option letters by removing any special characters
        const normalizedSelectedOption = selectedOptionLetter.trim().toLowerCase().replace(/[^a-zA-Z]/g, '');
        const normalizedCorrectOption = correctOptionLetter.trim().toLowerCase().replace(/[^a-zA-Z]/g, '');
    
        if (normalizedSelectedOption === normalizedCorrectOption) {
            toast.success("Correct answer!");
            setCorrectCount(prevCount => prevCount + 1);
        } else {
            toast.error(`Wrong answer. The correct answer is ${correctOptionLetter}`);
            setIncorrectCount(prevCount => prevCount + 1);
        }
    
        setSelectedOption({ questionIndex, selectedOptionLetter: normalizedSelectedOption });
        setAnsweredQuestions({
            ...answeredQuestions,
            [questionIndex]: true,
        });
    };
    

    useEffect(() => {
        setCorrectAnswersCount(correctCount);
        setIncorrectAnswersCount(incorrectCount);
    }, [correctCount, incorrectCount]);

    const saveQuiz = async () => {
        try {
            const response = await axiosInstance.post('/cases/quizzes/', {
                topic_or_file_name: quizTitle,
                correct_answers: correctCount,
                incorrect_answers: incorrectCount,
                content: JSON.stringify(data),
                user: user.user_id 
            });
            toast.success("Quiz saved successfully!");
        } catch (error) {
            toast.error("Failed to save quiz. Please try again.");
        }
    };

    return (
        <Container>
            {data.map((question, questionIndex) => (
                <Card key={questionIndex} className="mb-3">
                    <Card.Body>
                        <Card.Title className="card-title">{question.text}</Card.Title>
                        <Row>
                            {question.options.map((option, optionIndex) => (
                                <Col xs={12} sm={6} md={4} lg={3} xl={2} key={optionIndex}>
                                    <Button 
                                        variant={
                                            selectedOption?.questionIndex === questionIndex && 
                                            selectedOption?.selectedOptionLetter.trim() === question.correctOption 
                                                ? 'primary' 
                                                : 'outline-primary'
                                        }
                                        onClick={() => checkAnswer(option.trim()[0], question.correctOption, questionIndex)} 
                                        className="mb-2 w-100 custom-button" 
                                        disabled={answeredQuestions[questionIndex]}
                                    >
                                        {option}
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
            ))}
            <Container className="sticky-buttons">
                <SaveButton onClick={saveQuiz} />
                {/* <DownloadFile content={JSON.stringify(data)} fileName={`${quizTitle}.pdf`} /> */}
                <ListenButton text={data.map(q => q.text).join('\n')} />
            </Container>
        </Container>
    );
};

export default QuizCard;
