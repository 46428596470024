import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import useToast from "../../reusable-components/UseToast";
import ReactQuill from "react-quill";
import LoadingSpinner from "../../reusable-components/LoadingSpinner";
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import EditButton from "../../reusable-components/EditButton";
import SaveButton from "../../reusable-components/SaveButton";

const NotesDetail = () => {
  const [file, setFile] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedFile, setEditedFile] = useState({});
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const toast = useToast();
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status


  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axiosInstance.get(`/cases/files/${id}/`);
        if (response.status === 403) { 
          navigate("/notes");  
        } else {
          setFile(response.data);
          setIsFetched(true); // Set to true after file is fetched
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        if (error.response && error.response.status === 403) {
          navigate("/notes");
        } else {
          navigate("/notes");
        }
      }
    };
  
    // Only fetch file if it hasn't been fetched yet
    if (!isFetched) {
      fetchFile();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const handleEditClick = () => {
    setEditing(true);
    setEditedFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/cases/files/${id}/`, editedFile);
      setFile(editedFile);
      setEditing(false);
      toast.success("Class Note saved successfully");
    } catch (error) {
      toast.error("Error updating Class Note");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/files/${id}/`);
      setDeleted(true);
      toast.success("Note deleted successfully");
      navigate("/notes");
    } catch (error) {
      toast.error("Error deleting note");
    }
  };

  if (deleted) {
    return null;
  }

  if (!file) {
    return <LoadingSpinner />;
  }

  return (
    <Container className="left-align">
      <Breadcrumb items={[
        { label: 'Home', path: '/', active: false },
        { label: 'Note', path: '/note', active: false },
        { label: 'Notes', path: '/notes', active: false },
        { label: file?.name || 'Detail', path: `/note/${id}`, active: true },
      ]} />
      <Form className="pt-3 single-item-form">
        <Form.Group>
          {editing ? (
            <Form.Control
              name="name"
              type="text"
              value={editedFile.name}
              onChange={handleInputChange}
            />
          ) : (
            <h2 className="pb-3">{file.name}</h2>
          )}
        </Form.Group>
        <Form.Group>
          {editing ? (
            <ReactQuill
              name="content"
              value={editedFile.content}
              onChange={(value) => setEditedFile(prevState => ({ ...prevState, content: value }))}
              />
          ) : (
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: file.content }} />
          )}
        </Form.Group>
        {editing ? (
          <Container className="sticky-buttons">
            <SaveButton onClick={handleSaveClick} />
            <CancelButton onClick={() => setEditing(false)} />
          </Container>
        ) : (
          <Container className="sticky-buttons">
            <EditButton onClick={handleEditClick} />
            <ListenButton text={file.content} />
            <DeleteButton onClick={handleDeleteClick} />
          </Container>
        )}
      </Form>
    </Container>
  );
};

export default NotesDetail;