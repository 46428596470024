import React, { useState, useContext, useEffect } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import useAxios from "../../utils/UseAxios";
import { toast } from 'react-toastify';
import AuthContext from "../../utils/AuthContext";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../../reusable-components/Breadcrumb";

const StudentChatBot = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState(location.state?.messages || []);
    const [isLoading, setIsLoading] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const axiosInstance = useAxios();

    const configuration = new Configuration({
        apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);

    useEffect(() => {
        console.log("Loaded messages from location.state: ", location.state?.messages);
        // Only set the initial message if no messages exist and nothing is passed from location.state
        if (user && user.username && messages.length === 0) {
            setMessages([{ role: 'assistant', content: `Hello ${user.username}!<br />How can I help you today?` }]);
        }
    }, [user]); // Dependency array only includes 'user' to prevent infinite loop

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setShowSaveButton(false);
    
        const userMessage = { role: 'user', content: message };
        setMessages(prev => [...prev, userMessage]);
        setMessage("");
    
        try {
            const currentMessages = [...messages, userMessage];
            const response = await openai.createChatCompletion({
                model: "gpt-4o-mini",
                messages: currentMessages,
            });
    
            const assistantMessage = response.data.choices[0].message.content;
            setIsTyping(true);
            let displayedMessage = "";
            for (let i = 0; i < assistantMessage.length; i++) {
                displayedMessage += assistantMessage[i];
                setMessages(prev => {
                    const updatedMessages = [...currentMessages, { role: 'assistant', content: displayedMessage }];
                    return updatedMessages;
                });
                await new Promise(resolve => setTimeout(resolve, 50));
            }
    
            setIsTyping(false);
            setMessages(prev => [...currentMessages, { role: 'assistant', content: assistantMessage }]);
        } catch (error) {
            console.error("Error with OpenAI API request:", error);
        } finally {
            setIsLoading(false);
            setShowSaveButton(true);
        }
    };

    const generateChatName = () => {
        if (messages.length === 0) return "Chat";
        
        const firstUserMessage = messages.find(msg => msg.role === 'user')?.content;
        const chatName = firstUserMessage ? firstUserMessage.split(' ').slice(0, 3).join(' ') : "Chat";
        const today = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        return `${chatName} - ${today}`;
    };

    const handleSaveChat = async (event) => {
        event.preventDefault();
        const chatName = generateChatName();  // Generates a user-friendly chat name
        try {
            await axiosInstance.post("/cases/chats/", {
                user: user.user_id,
                content: messages.map(msg => `${msg.role}: ${msg.content}`).join('\n'),
                name: chatName  // Include the generated name here
            });
            toast.success("Chat saved successfully");
        } catch (error) {
            console.error("Error saving chat:", error);
            toast.error("Failed to save chat");
        }
    };
    

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Chat', path: '/chat', active: true },
    ];

    return (
        <div style={{ paddingBottom: "60px" }}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center chat-talk">
                    <Breadcrumb items={breadcrumbItems} />
                    <Link to="/chats" className="display-link" style={{ marginLeft: "auto" }}>
                        All Chats
                    </Link>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <ul className="chat-messages" style={{ listStyleType: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {messages.map((msg, index) => (
                                <li 
                                    key={index} 
                                    style={{ 
                                        marginBottom: "10px", 
                                        display: 'flex',
                                        justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start'
                                    }}
                                >
                                    <div 
                                        style={{ 
                                            backgroundColor: msg.role === 'user' ? '#FFFFFF' : '#E0E0E0',
                                            padding: '10px',
                                            borderRadius: '10px',
                                            maxWidth: '60%',
                                            color: '#000000'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: msg.content }}
                                    />
                                </li>
                            ))}
                            {isTyping && (
                                <li style={{ marginBottom: "10px", display: 'flex', justifyContent: 'flex-start' }}>
                                    <div 
                                        style={{ 
                                            backgroundColor: '#E0E0E0',
                                            padding: '10px',
                                            borderRadius: '10px',
                                            maxWidth: '60%',
                                            color: '#000000',
                                            fontStyle: 'italic'
                                        }}
                                    >
                                        Typing...
                                    </div>
                                </li>
                            )}
                        </ul>
                        <form className="chat-form container" onSubmit={handleSubmit} style={{ borderTop: 'none', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                                type="text"
                                value={message}
                                onChange={handleChange}
                                style={{ 
                                    flexGrow: 1, 
                                    padding: "10px", 
                                    borderRadius: "4px", 
                                    borderColor: "#2185C5", 
                                    marginRight: "10px" 
                                }}
                                placeholder="Type your message..."
                            />
                            <div className="button-group" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className='btn'
                                    type="submit"
                                    disabled={isLoading}
                                    style={{
                                        backgroundColor: "#FF9933",
                                        color: "#FFF",
                                        padding: "10px 20px",
                                        border: "none",
                                        borderRadius: "4px",
                                    }}
                                >
                                    Send
                                </button>
                                {showSaveButton && 
                                    <button type="button" onClick={handleSaveChat} className="btn save-chat-button" style={{ marginLeft: "10px", backgroundColor: "#28a745", color: "#FFF", padding: "10px 20px", border: "none", borderRadius: "4px" }}>
                                        Save
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentChatBot;
