import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link, useNavigate } from "react-router-dom";
import { IoEnterOutline } from "react-icons/io5";

const UserQuizzes = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [quizzes, setQuizzes] = useState([]);
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status
    const axiosInstance = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await axiosInstance.get(`/cases/quizzes/?user_id=${user.user_id}`);
        setQuizzes(response.data);
        setIsFetched(true); // Set to true after data is fetched
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };
  
    // Only fetch quizzes if user exists and data hasn't been fetched yet
    if (user && !isFetched) {
      fetchQuizzes();
    }
  }, [user, axiosInstance, isFetched]); // Add isFetched to the dependency array
  

  const displayQuizzes = displayAll ? quizzes : quizzes.slice(0, 5);

  const renderGrid = (quiz) => (
    <Col key={quiz.id} sm={12} md={6} lg={4} className="my-2 latest-feature-item">
      <Card>
        <Card.Body>
          <Link to={`/quiz/${quiz.id}`} className="card-title">
            {quiz.topic_or_file_name}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderList = (quiz) => (
    <Card key={quiz.id} className="my-2 latest-feature-item">
      <Card.Body>
        <Link to={`/quiz/${quiz.id}`} className="card-title">
          {quiz.topic_or_file_name}
        </Link>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="latest-feature-container">
      <h2>{displayAll ? "All Quizzes" : "Latest Quizzes"}</h2>
      {displayAll ? (
        <Row>{displayQuizzes.map(renderGrid)}</Row>
      ) : (
        displayQuizzes.map(renderList)
      )}
      {!displayAll && (
        <Container className="all-items-link mt-4 mb-5 go-container latest-feature-item" onClick={() => navigate("/quizzes")} style={{ cursor: 'pointer' }}>
          All Quizzes <IoEnterOutline size={24} /> 
        </Container>
      )}
    </Container>
  );
};

export default UserQuizzes;
