import React from "react";
import { Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa6";

const DeleteButton = ({ onClick }) => {
  return (
    <Button
      className="align-items-center mb-3 mt-3 action-buttons d-flex"
      onClick={onClick}
    >
      <FaTrash className="me-2" /> Delete
    </Button>
  );
};

export default DeleteButton;
