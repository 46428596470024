import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const loginUser = async (username, password) => {
    const response = await fetch("https://blog.doctrina.ai/members/token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      toast.success("Login successful");
      return true;
    } else {
      toast.error("Username or password not found");
      return false;
    }
  };

  const registerUser = async (username, password, password2, email) => {
    const response = await fetch("https://blog.doctrina.ai/members/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password,
        password2,
        email
      })
    });
  
    const data = await response.json();
  
    if (response.ok) {
      toast.success("Registration successful");
      return true;
    } else {
      let errorMessage = "Something went wrong!";
      if (data.username) {
        errorMessage = data.username[0];
      } else if (data.email) {
        errorMessage = data.email[0];
      } else if (data.password) {
        errorMessage = data.password[0];
      }
      toast.error(errorMessage);
      return false;
    }
  };
  
  

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};