import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import LoginPage from "../../authentication/LoginPage";
import useToast from "../../reusable-components/UseToast";
import { useNavigate } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import { Configuration, OpenAIApi } from "openai";
import * as Sentry from "@sentry/react";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import DropdownSelect from "../../reusable-components/DropdownSelect";
import LanguageSelector from "../../reusable-components/LanguageSelector";
import SaveButton from "../../reusable-components/SaveButton";
import ListenButton from "../../reusable-components/ListenButton";
import UserExams from "./UserExams";

const ExamGenerator = () => {
  const { user, authTokens } = useContext(AuthContext);
  const loggedIn = user !== null;
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [isGrading, setIsGrading] = useState(false);
  const [gradingResults, setGradingResults] = useState({});
  const [totalScore, setTotalScore] = useState(null);
  const [formDataObj, setFormDataObj] = useState({});
  const [choice, setChoice] = useState("General Topic");
  const [topicPlaceholder, setTopicPlaceholder] = useState("Geography");
  const [focusPlaceholder, setFocusPlaceholder] = useState("World capitals");
  const [fadeClass, setFadeClass] = useState("");

  const generalTopicPlaceholders = [
    { topic: "Geography", focus: "World capitals" },
    { topic: "History", focus: "World War I" },
    { topic: "Science", focus: "Photosynthesis" },
  ];

  const specificBookPlaceholders = [
    { topic: "A Brief History of Time by Stephen Hawking", focus: "Black Holes" },
    { topic: "The Selfish Gene by Richard Dawkins", focus: "Evolutionary Biology" },
    { topic: "Silent Spring by Rachel Carson", focus: "Environmental Science" },
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        if (choice === "General Topic") {
          setTopicPlaceholder(generalTopicPlaceholders[currentIndex].topic);
          setFocusPlaceholder(generalTopicPlaceholders[currentIndex].focus);
        } else if (choice === "Specific Book") {
          setTopicPlaceholder(specificBookPlaceholders[currentIndex].topic);
          setFocusPlaceholder(specificBookPlaceholders[currentIndex].focus);
        }
        setFadeClass("fade-in");
        setTimeout(() => {
          setFadeClass("");
        }, 200);
        currentIndex =
          (currentIndex + 1) %
          (choice === "General Topic"
            ? generalTopicPlaceholders.length
            : specificBookPlaceholders.length);
      }, 300);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [choice]);

  const handleChoiceChange = (e) => {
    setChoice(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setFormDataObj((prevState) => ({ ...prevState, language: e.target.value }));
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (choice === "Upload File") {
      handleFileUpload();
      return;
    }

    const formData = new FormData(e.target);
    const localFormDataObj = Object.fromEntries(formData.entries());
    setFormDataObj(localFormDataObj);

    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);

    setIsLoading(true);

    try {
      const promptMessage = `Generate a highly detailed exam with ${localFormDataObj.numQuestions} ${localFormDataObj.difficulty} questions on the topic "${localFormDataObj.topic}" focusing on "${localFormDataObj.focus}". The exam should include a mix of fill-in-the-blank questions and explanatory questions where the user must describe or explain a concept in detail. Do not include any instructions or labels like "Fill-in-the-Blank" or "Detailed Explanation" in the questions. Reply in ${localFormDataObj.language}.`;

      const response = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant with expertise in education.",
          },
          {
            role: "user",
            content: promptMessage,
          },
        ],
      });

      console.log("OpenAI Response:", response.data);

      const generatedQuestions = parseQuestions(
        response.data.choices[0].message.content
      );
      setQuestions(generatedQuestions);
      setHeading(localFormDataObj.topic || "Generated Exam");
    } catch (error) {
      Sentry.captureException(error);
      toast.error(
        "An error occurred while generating the exam. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const parseQuestions = (content) => {
    return content
      .split("\n")
      .filter((q) => q.trim() !== "")
      .map((q, index) => ({
        question: q.trim(),
        id: index + 1,
      }));
  };

  const handleFileUpload = async () => {
    const file = formDataObj.fileContent;

    if (!file) {
      toast.error("No file selected");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("Maximum file size for upload should be less than 5MB");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("uploaded_file", file);

    try {
      const fileUploadResponse = await axiosInstance.post(
        "/file_processing/upload/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const extractedText = fileUploadResponse.data.file_content;
      console.log("Extracted Text:", extractedText);

      const configuration = new Configuration({
        apiKey: "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",
      });
      const openai = new OpenAIApi(configuration);

      const openaiResponse = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          {
            role: "user",
            content: `Please generate an exam in ${formDataObj.language} with a focus on "${extractedText}". The exam should include a mix of fill-in-the-blank questions and explanatory questions. Do not include any instructions or labels like "Fill-in-the-Blank" or "Detailed Explanation" in the questions.`,
          },
        ],
      });

      console.log("OpenAI Response for File:", openaiResponse.data);

      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      setHeading(fileNameWithoutExtension);
      setQuestions(
        parseQuestions(openaiResponse.data.choices[0].message.content)
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to process file. Please upload book or clear image");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGradeExam = async () => {
    setIsGrading(true);
    let completeResults = {};
    let completeScore = 0;

    try {
      const configuration = new Configuration({
        apiKey: "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",
      });
      const openai = new OpenAIApi(configuration);

      const gradingPrompt = questions
        .map((q, index) => {
          return `Question ${index + 1}: ${q.question}\nAnswer: ${
            answers[index] || "No answer provided"
          }\n`;
        })
        .join("\n");

      const gradingResponse = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant and a strict grader.",
          },
          {
            role: "user",
            content: `Please grade the following exam answers strictly. Only provide the score for each question and a explanation for the grade, along with what would be the best answer. Do not include any overall comments or summary statements. Grade each question separately as follows: "Question X: Score: Y/10. Explanation: Z".\n\n${gradingPrompt}`,
          },
        ],
      });

      const gradingResultsArray = gradingResponse.data.choices[0].message.content
        .split("\n")
        .filter((line) => line.trim() !== "" && line.startsWith("Question"));

      const parsedResults = parseGradingResults(gradingResultsArray);

      // Ensure all results are complete before setting state
      if (
        parsedResults &&
        parsedResults.results &&
        Object.keys(parsedResults.results).length === questions.length &&
        Object.values(parsedResults.results).every(
          (result) => result.grade && result.explanation
        )
      ) {
        completeResults = parsedResults.results;
        completeScore = parsedResults.totalScore;
        setGradingResults({ ...completeResults });
        setTotalScore(completeScore);
        toast.success("Exam graded successfully.");
      } else {
        throw new Error("Incomplete grading data received.");
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("An error occurred while grading the exam. Please try again.");
    } finally {
      setIsGrading(false);
    }
  };

  const parseGradingResults = (gradingResultsArray) => {
    let results = {};
    let totalScore = 0;
    let currentQuestion = -1;

    gradingResultsArray.forEach((line, index) => {
      console.log(`Parsing line ${index}:`, line);

      const questionMatch = line.match(/Question (\d+):/);
      if (questionMatch) {
        currentQuestion = parseInt(questionMatch[1]) - 1;
        results[currentQuestion] = { grade: '', explanation: '' };
      }

      const scoreMatch = line.match(/Score: (\d+)\/10/);
      if (scoreMatch) {
        const score = parseInt(scoreMatch[1], 10);
        totalScore += score;
        if (currentQuestion !== null) {
          results[currentQuestion].grade = `Score: ${score}/10`;
        }
      }

      if (line.includes("Explanation:")) {
        const explanation = line.split("Explanation:")[1].trim();
        if (currentQuestion !== null && results[currentQuestion]) {
          results[currentQuestion].explanation = explanation;
        }
      }
    });

    console.log("Final Grading Results Before Setting State:", results);
    console.log("Total Score Before Setting State:", totalScore);

    return { results, totalScore };
  };

  const handleAnswerChange = (e, index) => {
    const { value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,
    }));
  };

  const handleSaveExam = async () => {
    try {
      const examData = {
        user: user.user_id,
        book: heading,
        difficulty: formDataObj.difficulty,
        content: JSON.stringify(
          questions.map((q, index) => ({
            question: q.question,
            answer: answers[index],
            grading: gradingResults[index],
          }))
        ),
        total_score: totalScore,
      };

      console.log("Exam Data to Save:", examData);

      const saveResponse = await axiosInstance.post("/cases/exams/", examData);
      toast.success("Exam saved successfully");
      navigate(`/exam/${saveResponse.data.id}`);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to save exam");
    }
  };

  const canSubmitForGrading = () => {
    return (
      questions.length > 0 &&
      Object.keys(answers).length === questions.length &&
      Object.values(answers).every((answer) => answer.trim() !== "")
    );
  };

  if (!loggedIn) {
    return <LoginPage />;
  }

  return (
    <div>
      <Container className="app-container pt-4">
        <Breadcrumb
          items={[
            { label: "Home", path: "/", active: false },
            { label: "Exam", path: "/exam", active: true },
          ]}
        />
        <h3 style={{ textAlign: "left", paddingLeft: "20px" }}>
          Interactive Exam Generator
        </h3>
        <p
          style={{
            textAlign: "left",
            paddingLeft: "20px",
            paddingBottom: "20px",
          }}
        >
          Develop exams covering a wide range of topics from the Renaissance to
          modern physics, testing your knowledge from Leonardo da Vinci's art to
          Einstein's theory of relativity.
        </p>
        <Row>
          <Col md={12}>
            <Form style={{ paddingLeft: "20px" }} onSubmit={onFormSubmit}>
              <FormGroup className="d-flex align-items-center">
                <FormLabel
                  className="app-form-label me-2"
                  style={{ width: "120px", fontWeight: "bold" }}
                >
                  Options
                </FormLabel>
                <DropdownSelect
                  name="choice"
                  options={[
                    { label: "General Topic", value: "General Topic" },
                    { label: "Specific Book", value: "Specific Book" },
                    { label: "Upload File", value: "Upload File" },
                  ]}
                  value={choice}
                  onChange={handleChoiceChange}
                  className="dropdown-select"
                />
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <FormLabel
                  className="app-form-label me-2"
                  style={{ width: "120px", fontWeight: "bold" }}
                >
                  Language
                </FormLabel>
                <LanguageSelector
                  onLanguageChange={handleLanguageChange}
                  className="dropdown-select"
                />
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <FormLabel
                  className="app-form-label me-2"
                  style={{ width: "120px", fontWeight: "bold" }}
                >
                  Difficulty
                </FormLabel>
                <DropdownSelect
                  name="difficulty"
                  options={[
                    { label: "Easy", value: "Easy" },
                    { label: "Medium", value: "Medium" },
                    { label: "Hard", value: "Hard" },
                  ]}
                  defaultValue="Easy"
                  className="dropdown-select"
                />
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <FormLabel
                  className="app-form-label me-2"
                  style={{ width: "120px", fontWeight: "bold" }}
                >
                  Number of Questions
                </FormLabel>
                <DropdownSelect
                  name="numQuestions"
                  options={[
                    { label: "5", value: "5" },
                    { label: "10", value: "10" },
                  ]}
                  defaultValue="5"
                  className="dropdown-select"
                />
              </FormGroup>

              {choice !== "Upload File" && (
                <>
                  <FormGroup className="d-flex align-items-center">
                    <FormLabel
                      className="app-form-label me-2"
                      style={{ width: "120px", fontWeight: "bold" }}
                    >
                      Subject
                    </FormLabel>
                    <FormControl
                      type="text"
                      name="topic"
                      placeholder={topicPlaceholder}
                      required
                      className={`app-input ${fadeClass}`}
                    />
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center">
                    <FormLabel
                      className="app-form-label me-2"
                      style={{ width: "120px", fontWeight: "bold" }}
                    >
                      Focus
                    </FormLabel>
                    <FormControl
                      type="text"
                      name="focus"
                      placeholder={focusPlaceholder}
                      required
                      className={`app-input ${fadeClass}`}
                    />
                  </FormGroup>
                </>
              )}

              {choice === "Upload File" && (
                <FormGroup className="d-flex align-items-center">
                  <FormLabel
                    className="app-form-label me-2"
                    style={{ width: "120px", fontWeight: "bold" }}
                  >
                    Upload File
                  </FormLabel>
                  <FormControl
                    type="file"
                    onChange={(e) =>
                      setFormDataObj((prevState) => ({
                        ...prevState,
                        fileContent: e.target.files[0],
                      }))
                    }
                    required
                    className="app-input"
                  />
                </FormGroup>
              )}

              <Container
                className="submit-container"
                style={{ textAlign: "left" }}
              >
                <Button className="submit-button" type="submit" disabled={isLoading}>
                  {isLoading ? "Generating..." : "Submit"}
                </Button>
              </Container>
            </Form>

            {questions.length > 0 && (
              <>
                <h3>{heading}</h3>
                <Form>
                  {questions.map((q, index) => (
                    <FormGroup key={index}>
                      <FormLabel>{q.question}</FormLabel>
                      <FormControl
                        as="textarea"
                        rows={q.question.toLowerCase().includes("explain") ? 3 : 1}
                        placeholder={
                          q.question.toLowerCase().includes("explain")
                            ? "Provide your detailed explanation..."
                            : "Your answer..."
                        }
                        value={answers[index] || ""}
                        onChange={(e) => handleAnswerChange(e, index)}
                      />
                      {gradingResults[index] && gradingResults[index].grade ? (
                        <div className="grading-result">
                          <p><strong>{gradingResults[index].grade}</strong></p>
                          <p>{gradingResults[index].explanation}</p>
                        </div>
                      ) : (
                        isGrading ? (
                          <p>Grading in progress...</p>
                        ) : (
                          <p></p> // Show nothing if grading has not started yet
                        )
                      )}
                    </FormGroup>
                  ))}
                </Form>
                <Container className="sticky-buttons">
                  <Button
                    className="mt-5 mb-5 submit-button"
                    type="button"
                    onClick={handleGradeExam}
                    disabled={!canSubmitForGrading()}
                  >
                    {isGrading ? "Grading..." : "Grade"}
                  </Button>
                  <SaveButton onClick={handleSaveExam} />
                  <ListenButton
                    text={questions
                      .map((q, index) => `${q.question}: ${answers[index]}`)
                      .join("\n")}
                  />
                </Container>
              </>
            )}

            {totalScore !== null && (
              <div className="total-score">
                <h3>
                  Total Score: {totalScore} / {questions.length * 10}
                </h3>
              </div>
            )}

            <Row className="mt-5">
              <Col className="d-flex flex-column align-items-start">
                <UserExams displayAll={false} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExamGenerator;
