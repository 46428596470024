import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import LoadingSpinner from "../../reusable-components/LoadingSpinner";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import EditButton from "../../reusable-components/EditButton";
import SaveButton from "../../reusable-components/SaveButton";

const EssayDetail = () => {
  const [essay, setEssay] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedEssay, setEditedEssay] = useState({});
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status


  useEffect(() => {
    const fetchEssay = async () => {
      try {
        const response = await axiosInstance.get(`/cases/essays/${id}/`);
        if (response.status === 403) {
          navigate("/essays");
        } else {
          setEssay(response.data);
          setIsFetched(true); // Set to true after essay is fetched
        }
      } catch (error) {
        console.error("Error fetching essay:", error);
        if (error.response && error.response.status === 403) {
          navigate("/essays");
        } else {
          navigate("/essays");
        }
      }
    };
  
    // Only fetch essay if it hasn't been fetched yet
    if (!isFetched) {
      fetchEssay();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const handleEditClick = () => {
    setEditing(true);
    setEditedEssay(essay);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedEssay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/cases/essays/${id}/`, editedEssay);
      setEssay(editedEssay);
      setEditing(false);
      setIsFetched(false); // Reset to allow refetching if needed
      toast.success("Essay updated successfully");
    } catch (error) {
      toast.error("Failed to update essay");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/essays/${id}/`);
      setDeleted(true);
      toast.success("Essay deleted successfully!");
      navigate("/essays");
    } catch (error) {
      console.error("Error deleting essay:", error);
      toast.error("Failed to delete essay");
    }
  };

  if (deleted) {
    return null;
  }

  if (!essay) {
    return <LoadingSpinner />;
  }

  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Essay', path: '/essay', active: false },
    { label: 'Essays', path: '/essays', active: false },
    { label: essay?.title || 'Essay Details', path: `/essay/${id}`, active: true },
  ];

  return (
    <Container className="left-align">
      <Breadcrumb items={breadcrumbItems} />
      <Form className="pt-3 single-item-form">
        <Form.Group>
          {editing ? (
            <Form.Control
              name="title"
              type="text"
              value={editedEssay.title}
              onChange={handleInputChange}
            />
          ) : (
            <h2 className="pb-3">{essay.title}</h2>
          )}
        </Form.Group>
        <Form.Group>
          {editing ? (
            <ReactQuill
              name="content"
              value={editedEssay.content}
              onChange={(value) => setEditedEssay(prevState => ({ ...prevState, content: value }))}
            />
          ) : (
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: essay.content }} />
          )}
        </Form.Group>
        {editing ? (
          <Container className="sticky-buttons">
            <SaveButton onClick={handleSaveClick} />
            <CancelButton onClick={() => setEditing(false)} />
          </Container>
        ) : (
          <Container className="sticky-buttons">
            {/* <DownloadFile content={essay.content} fileName={`${essay.title}.pdf`} /> */}
            <ListenButton text={essay.content} />
            <EditButton onClick={handleEditClick} />
            <DeleteButton onClick={handleDeleteClick} />
          </Container>
        )}
      </Form>
    </Container>
  );
};

export default EssayDetail;
