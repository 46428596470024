import React from "react";
import UserFiles from "./UserNotes";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import { Container } from "react-bootstrap";

const AllNotes = () => {
  return (
    <Container>
      <Breadcrumb items={[
        { label: 'Home', path: '/', active: false },
        { label: 'Note', path: '/note', active: false },
        { label: 'Notes', path: '/notes', active: true }
      ]} />
      <UserFiles displayAll={true} />
    </Container>
  );
};

export default AllNotes;
