import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link, useNavigate } from "react-router-dom";
import { IoEnterOutline } from "react-icons/io5";

const UserSummaries = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [summaries, setSummaries] = useState([]);
  const [isFetched, setIsFetched] = useState(false); 
    const axiosInstance = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSummaries = async () => {
      try {
        const response = await axiosInstance.get(`/cases/summaries/?user_id=${user.user_id}`);
        setSummaries(response.data);
        setIsFetched(true); 
      } catch (error) {
        console.error("Error fetching summaries:", error);
      }
    };
  
    if (user && !isFetched) {
      fetchSummaries();
    }
  }, [user, axiosInstance, isFetched]); 
  

  const displaySummaries = displayAll ? summaries : summaries.slice(0, 5);

  const renderGrid = (summary) => (
    <Col key={summary.id} sm={12} md={6} lg={4} className="my-2 latest-feature-item">
      <Card>
        <Card.Body>
          <Link to={`/summary/${summary.id}`} className="card-title">
            {summary.book}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderList = (summary) => (
    <Card key={summary.id} className="my-2 latest-feature-item">
      <Card.Body>
        <Link to={`/summary/${summary.id}`} className="card-title">
          {summary.book}
        </Link>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="latest-feature-container">
      <h2>{displayAll ? "All Summaries" : "Latest Summaries"}</h2>
      {displayAll ? (
        <Row>{displaySummaries.map(renderGrid)}</Row>
      ) : (
        displaySummaries.map(renderList)
      )}
      {!displayAll && (
        <Container className="all-items-link mt-4 mb-5 go-container latest-feature-item" onClick={() => navigate("/summaries")} style={{ cursor: 'pointer' }}>
          All Summaries <IoEnterOutline size={24} /> 
        </Container>
      )}
    </Container>
  );
};

export default UserSummaries;
