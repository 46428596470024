import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import { useContext } from 'react';
import AuthContext from '../utils/AuthContext';

export default function Layout({ children }) {
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const noNavRoutes = ['/register', '/login', '/', '/forgot-password'];

  const isResetPasswordRoute = location.pathname.startsWith('/reset-password/' || location.pathname === '/thank-you');

  return (
    <div className="App">
      {(!isResetPasswordRoute && (user || !noNavRoutes.includes(location.pathname))) && <Navigation />}
      {children}
    </div>
  );
}
