import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from "react-bootstrap";
import useToast from '../reusable-components/UseToast';

const ThankYou = () => {
  const navigate = useNavigate();
  const toast = useToast(); 
  const toastShownRef = useRef(false);

  useEffect(() => {
    if (!toastShownRef.current) {
      toast.success("Payment successful");
      toastShownRef.current = true;
    }

    const loadLottiePlayer = () => {
      const script = document.createElement("script");
      script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
      script.type = "module";
      document.body.appendChild(script);
    };

    loadLottiePlayer();
  }, [toast]);

  return (
    <Container fluid className="thank-you">
      <Row className="justify-content-md-center">
        <Col md={8} className="text-center">
          <dotlottie-player 
            src="https://lottie.host/076f6868-002a-4b18-836c-17154585a36f/uAANTFW3s4.json" 
            background="transparent" 
            speed="1" 
            style={{ width: '400px', height: 'auto', margin: 'auto' }} 
            loop 
            autoplay>
          </dotlottie-player>
          <h1>Thank You for Your Purchase</h1>
          <p>Your transaction has been completed successfully. We have emailed you the details of your order.</p>
          <Button 
            variant="primary" 
            onClick={() => navigate('/')} 
            className="w-50 mt-4 login-button"
          >
            Start Learning
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYou;
