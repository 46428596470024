import React from "react";
import UserEssays from "./UserEssays";
import Breadcrumb from "../../reusable-components/Breadcrumb"; 
import { Container } from "react-bootstrap";

const AllEssays = () => {
  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Essay', path: '/essay', active: false },
    { label: 'Essays', path: '/essays', active: true },
  ];

  return (
    <Container>
      <Breadcrumb items={breadcrumbItems} /> 
      <UserEssays displayAll={true} />
    </Container>
  );
};

export default AllEssays;