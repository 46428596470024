import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FaEarListen, FaStop } from "react-icons/fa6";

const speechSynthesis = window.speechSynthesis;
const SpeechSynthesisUtterance = window.SpeechSynthesisUtterance;

const ListenButton = ({ text }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const location = useLocation();

  const stopSpeech = () => {
    speechSynthesis.cancel();
    setIsSpeaking(false);
  };

  const synthesizeSpeech = () => {
    if (isSpeaking) {
      stopSpeech();
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
    utterance.onend = () => setIsSpeaking(false);
    setIsSpeaking(true);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      stopSpeech();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      stopSpeech();
    };
  }, []);

  useEffect(() => {
    stopSpeech();
  }, [location]);

  return (
    <Button
      className="align-items-center mb-3 mt-3 action-buttons d-flex"
      onClick={synthesizeSpeech}
    >
      {isSpeaking ? (
        <FaStop className="me-2" />
      ) : (
        <FaEarListen className="me-2" />
      )}
      {isSpeaking ? "Stop" : "Listen"}
    </Button>
  );
};

export default ListenButton;
