import React from "react";
import { Button } from "react-bootstrap";
import { FaDeleteLeft } from "react-icons/fa6";

const CancelButton = ({ onClick }) => {
  return (
    <Button
      className="d-flex align-items-center mb-3 mt-3 action-buttons"
      onClick={onClick}
    >
      <FaDeleteLeft className="me-2" /> Cancel
    </Button>
  );
};

export default CancelButton;
