import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const UserChats = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const [isFetched, setIsFetched] = useState(false); 
    const axiosInstance = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axiosInstance.get(`/cases/chats/?user_id=${user.user_id}`);
        setChats(response.data);
        setIsFetched(true); 
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };
  
    if (user && !isFetched) {
      fetchChats();
    }
  }, [user, axiosInstance, isFetched]); 
  

  const displayChats = displayAll ? chats : chats.slice(0, 5);

  return (
    <div>
        <h2 className="my-4">{displayAll ? "All Chats" : "Latest Chats"}</h2>
        {displayChats.map((chat) => (
            <div key={chat.id} className="my-2">
                <Link to={`/chat/${chat.id}`} className="card-title">
                    {chat.name || `Chat on ${new Date(chat.created_at).toLocaleString()}`}
                </Link>
            </div>
        ))}
        {!displayAll && <Button className="get-started-button" onClick={() => navigate("/chats")}>All Chats</Button>}
    </div>
);

};

export default UserChats;
