import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as BootstrapBreadcrumb } from 'react-bootstrap';

const Breadcrumb = ({ items }) => {
  return (
    <BootstrapBreadcrumb style={{ paddingLeft: '20px' }}>
      {items.map((item, index) => (
        <BootstrapBreadcrumb.Item key={index} active={item.active}>
          {item.active ? (
            item.label
          ) : (
            <Link to={item.path}>{item.label}</Link>
          )}
        </BootstrapBreadcrumb.Item>
      ))}
    </BootstrapBreadcrumb>
  );
};

export default Breadcrumb;
