import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, FormGroup, FormLabel, Button, Form, FormControl } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import LoginPage from '../../authentication/LoginPage';
import useToast from "../../reusable-components/UseToast";
import { useNavigate } from "react-router-dom";
import UserEssays from "./UserEssays";
import useAxios from "../../utils/UseAxios";
import SaveButton from "../../reusable-components/SaveButton";
import { Configuration, OpenAIApi } from "openai";
import * as Sentry from "@sentry/react";
import LanguageSelector from "../../reusable-components/LanguageSelector";
import DropdownSelect from '../../reusable-components/DropdownSelect';
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import Footer from "../../reusable-components/Footer";

const EssayGenerator = () => {
  const { user, authTokens } = useContext(AuthContext);
  const loggedIn = user !== null;
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState("");
  const [response, setResponse] = useState("");
  const [formDataObj, setFormDataObj] = useState(null);
  const [choice, setChoice] = useState("General Topic");
  const [topicPlaceholder, setTopicPlaceholder] = useState("Literature");
  const [focusPlaceholder, setFocusPlaceholder] = useState("Shakespeare's works");
  const [fadeClass, setFadeClass] = useState("");

  const generalTopicPlaceholders = [
    { topic: "Literature", focus: "Shakespeare's works" },
    { topic: "Science", focus: "Evolutionary theory" },
    { topic: "Philosophy", focus: "Existentialism" }
  ];

  const specificBookPlaceholders = [
    { topic: "Moby Dick by Herman Melville", focus: "Obsession and Revenge" },
    { topic: "1984 by George Orwell", focus: "Totalitarianism" },
    { topic: "To Kill a Mockingbird by Harper Lee", focus: "Racial Injustice" }
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        if (choice === "General Topic") {
          setTopicPlaceholder(generalTopicPlaceholders[currentIndex].topic);
          setFocusPlaceholder(generalTopicPlaceholders[currentIndex].focus);
        } else if (choice === "Specific Book") {
          setTopicPlaceholder(specificBookPlaceholders[currentIndex].topic);
          setFocusPlaceholder(specificBookPlaceholders[currentIndex].focus);
        }
        setFadeClass("fade-in");
        setTimeout(() => {
          setFadeClass("");
        }, 200);
        currentIndex = (currentIndex + 1) % (choice === "General Topic" ? generalTopicPlaceholders.length : specificBookPlaceholders.length);
      }, 300);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [choice]);

  const handleChoiceChange = (e) => {
    setChoice(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setFormDataObj(prevState => ({ ...prevState, language: e.target.value }));
  };

  const handleTextAreaChange = (e) => {
    setResponse(e.target.value);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    
    if (choice === "Upload File") {
      handleFileUpload();
      return;
    }

    const formData = new FormData(e.target);
    const localFormDataObj = Object.fromEntries(formData.entries());

    setFormDataObj(localFormDataObj);

    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);

    setIsLoading(true);

    try {
      const apiResponse = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          { role: "user", content: `Write an essay in ${localFormDataObj.language} titled "${localFormDataObj.suggestion}" focusing on "${localFormDataObj.focus}" of type "${localFormDataObj.essayType}"` },
        ],
      });

      setHeading(`${localFormDataObj.suggestion}`);
      setResponse(apiResponse.data.choices[0].message.content);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("An error occurred while generating the essay. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveEssay = async () => {
    const formData = new FormData(document.querySelector("form"));
    const formDataObj = Object.fromEntries(formData.entries());
  
    if (!formDataObj.focus) {
      formDataObj.focus = "Summary of uploaded file"; 
    }
  
    try {
      const saveResponse = await axiosInstance.post("/cases/essays/", {
        user: user.user_id,
        title: heading,
        content: response,
        focus: formDataObj.focus,
        essay_type: formDataObj.essayType,
      }, {
        headers: {
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      toast.success("Essay saved successfully");
      navigate(`/essay/${saveResponse.data.id}`);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to save essay");
    }
  };

  const formatResponse = (responseText) => {
    const paragraphs = responseText.split("\n\n");
    return paragraphs.map((paragraph, index) => (
      <p key={index} style={{ textAlign: "left" }}>
        {paragraph}
      </p>
    ));
  };

  const handleFileUpload = async () => {
    const file = formDataObj.fileContent;
  
    if (!file) {
      toast.error("No file selected");
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      toast.error("Maximum file size for upload should be less than 5MB");
      return;
    }
  
    setIsLoading(true);  
    const formData = new FormData();
    formData.append("uploaded_file", file);
  
    try {
      const fileUploadResponse = await axiosInstance.post("/file_processing/upload/", formData, {
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'multipart/form-data'
        }
      });
  
      const extractedText = fileUploadResponse.data.file_content;
      console.log("Extracted Text:", extractedText);
  
      const configuration = new Configuration({
        apiKey: "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",
      });
      const openai = new OpenAIApi(configuration);
  
      const openaiResponse = await openai.createChatCompletion({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          { role: "user", content: `Please generate an essay in ${formDataObj.language} with a focus on "${extractedText}". In the "${extractedText}" focus to find author, table of content and other useful info and create essay based on what you know about that specific information.  The essay should be ${formDataObj.essayType}` },
        ],
      });
  
      // Set the heading to the name of the file (without the extension)
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      setHeading(fileNameWithoutExtension);
      setResponse(openaiResponse.data.choices[0].message.content);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to process file. Please upload book or clear image");
    } finally {
      setIsLoading(false);  
    }
  };
  
  

  if (!loggedIn) {
    return <LoginPage />;
  }

  return (
    <>
      <Container className="app-container pt-4">
        <Breadcrumb items={[
          { label: 'Home', path: '/', active: false },
          { label: 'Essay', path: '/essay', active: true }
        ]} />
        <h3 style={{ textAlign: "left", paddingLeft: "20px" }}>Interactive Essay Generator</h3>
        <p style={{ textAlign: "left", paddingLeft: "20px", paddingBottom: "20px" }}>
          Compose essays on diverse topics from environmental issues to classical philosophy, guiding your writing from climate change to Aristotle's ethics.
        </p>
        <Row>
          <Col md={12}>
            <Form style={{ paddingLeft: "20px" }} onSubmit={onFormSubmit}>
              <FormGroup className="d-flex align-items-center">
                <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Options</FormLabel>
                <DropdownSelect
                  name="choice"
                  options={[
                    { label: 'General Topic', value: 'General Topic' },
                    { label: 'Specific Book', value: 'Specific Book' },
                    { label: 'Upload File', value: 'Upload File' }
                  ]}
                  value={choice}
                  onChange={handleChoiceChange}
                  className="dropdown-select"
                />
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Language</FormLabel>
                <LanguageSelector onLanguageChange={handleLanguageChange} className="dropdown-select" />
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Type</FormLabel>
                <DropdownSelect
                  name="essayType"
                  options={[
                    { label: 'Argumentative', value: 'Argumentative' },
                    { label: 'Descriptive', value: 'Descriptive' },
                    { label: 'Expository', value: 'Expository' },
                    { label: 'Narrative', value: 'Narrative' },
                    { label: 'Persuasive', value: 'Persuasive' }
                  ]}
                  value={formDataObj?.essayType || 'Argumentative'}
                  onChange={(e) => {
                    setFormDataObj(prevState => ({ ...prevState, essayType: e.target.value }));
                  }}
                  className="dropdown-select"
                />
              </FormGroup>

              {choice !== "Upload File" && (
                <>
                  <FormGroup className="d-flex align-items-center">
                    <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Subject</FormLabel>
                    <FormControl
                      type="text"
                      name="suggestion"
                      placeholder={topicPlaceholder}
                      required
                      className={`app-input ${fadeClass}`}
                    />
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center">
                    <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Focus</FormLabel>
                    <FormControl
                      type="text"
                      name="focus"
                      placeholder={focusPlaceholder}
                      value={formDataObj?.focus || ''}
                      onChange={(e) => setFormDataObj(prevState => ({ ...prevState, focus: e.target.value }))}
                      className={`app-input ${fadeClass}`}
                    />
                  </FormGroup>
                </>
              )}

              {choice === "Upload File" && (
                <FormGroup className="d-flex align-items-center">
                  <FormLabel className="app-form-label me-2" style={{ width: '120px', fontWeight: 'bold' }}>Upload File</FormLabel>
                  <FormControl 
                    type="file" 
                    onChange={(e) => setFormDataObj(prevState => ({ ...prevState, fileContent: e.target.files[0] }))} 
                    required 
                    className="app-input"
                  />
                </FormGroup>
              )}

              <Container className="submit-container" style={{ textAlign: 'left' }}>
                <Button className="submit-button" type="submit" disabled={isLoading}>
                  {isLoading ? 'Generating...' : 'Submit'}
                </Button>
              </Container>
            </Form>

            {response && (
              <Container style={{ backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "8px", marginTop: "20px" }}>
                <h4 style={{ textAlign: "left" }}>{heading}</h4>
                {formatResponse(response)}
              </Container>
            )}

            {response && (
              <Container className="sticky-buttons">
                <SaveButton onClick={handleSaveEssay} />
                <ListenButton text={response} />
              </Container>
            )}
          </Col>
        </Row>
        <Row className={`latest-essays mt-5`}>
          <Col className="d-flex flex-column align-items-start">
            <UserEssays displayAll={false} />
          </Col>
        </Row>
        {response && <Footer />}
      </Container>
    </>
  );
};

export default EssayGenerator;
