import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToast from "../reusable-components/UseToast";
import useAxios from "./UseAxios";
import AuthContext from "./AuthContext";

const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
    const loggedIn = user !== null;
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const toast = useToast();
    const [hasCheckedPayment, setHasCheckedPayment] = useState(false);
    const [hasPaid, setHasPaid] = useState(false);

    const checkPaymentStatus = async () => {
        if (!loggedIn) {
            navigate("/");
            return;
        }

        try {
            const res = await axiosInstance.get("/payments/check-payment/");
            if (res.data.status !== 'COMPLETED' && res.data.status !== 'active') {
                toast.error("This is a paid feature");
                navigate("/");
            } else {
                setHasPaid(true);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setHasCheckedPayment(true);
        }
    };

    useEffect(() => {
        checkPaymentStatus();
    }, [loggedIn, axiosInstance, navigate, toast]);

    return hasCheckedPayment && hasPaid ? <>{children}</> : null;
};

export default ProtectedRoute;
