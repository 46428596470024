import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "./AuthContext";
import { getCookie } from "./CookieHelper";

const baseURL = "https://blog.doctrina.ai/";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
      "X-CSRFToken": getCookie("csrftoken"),
    },
    withCredentials: true,
  });
  
  

  axiosInstance.interceptors.request.use(async (req) => {
    if (authTokens) {
      const user = jwt_decode(authTokens.access);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 0; 
  
      if (isExpired) {
        const response = await axios.post(`${baseURL}members/token/refresh/`, {
          refresh: authTokens.refresh,
        });
  
        localStorage.setItem("authTokens", JSON.stringify(response.data));
        setAuthTokens(response.data);
        setUser(jwt_decode(response.data.access));
  
        req.headers.Authorization = `Bearer ${response.data.access}`;
      }
    }
  
    return req; 
  });
  
  


  return axiosInstance;
};

export default useAxios;
