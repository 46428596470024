import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import EditButton from "../../reusable-components/EditButton";
import SaveButton from "../../reusable-components/SaveButton";

const ChatDetails = () => {
  const [chat, setChat] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedChat, setEditedChat] = useState({});
  const axiosInstance = useAxios();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status


  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Chat', path: '/chat', active: false },
    { label: 'Chats', path: '/chats', active: false },
    { label: `Chat Details`, path: `/chat/${id}`, active: true },
  ];

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await axiosInstance.get(`/cases/chats/${id}/`);
        setChat(response.data);
        setIsFetched(true); // Set to true after chat is fetched
        console.log("Fetched chat data: ", response.data); 
      } catch (error) {
        console.error("Error fetching chat:", error);
      }
    };
  
    // Only fetch chat if it hasn't been fetched yet
    if (!isFetched) {
      fetchChat();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const parseMessages = (content) => {
    if (!content) return [];
    return content.split('\n').map(line => {
      const [role, ...messageParts] = line.split(': ');
      return {
        role: role.trim(),
        content: messageParts.join(': ').trim(),
      };
    });
  };

  const handleEditClick = () => {
    setEditing(true);
    setEditedChat(chat);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedChat((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/cases/chats/${id}/`, editedChat);
      setChat(editedChat);
      toast.success("Chat updated successfully");
      setEditing(false);
      setIsFetched(false); // Reset to allow refetching if needed
    } catch (error) {
      toast.error("Failed to update chat");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/chats/${id}/`);
      toast.success("Chat deleted successfully");
      navigate("/chat");
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  return (
    <Container className="left-align pt-2">
      <Breadcrumb items={breadcrumbItems} />
      {chat && (
        <>
          {editing ? (
            <Form className="pt-3">
              <Form.Group className="container">
                <ReactQuill 
                  value={editedChat.content}
                  onChange={(content, delta, source, editor) => {
                    const value = editor.getHTML();
                    setEditedChat((prevState) => ({
                      ...prevState,
                      content: value,
                    }));
                  }}
                />
              </Form.Group>
              <Container className="sticky-buttons">
                <SaveButton onClick={handleSaveClick} />
                <CancelButton onClick={() => setEditing(false)} />
              </Container>
            </Form>
          ) : (
            <>
              <h2>{chat.timestamp}</h2>
              <div className="response-saved"
                style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{ __html: chat.content }}
              />
              <Container className="sticky-buttons">
                <EditButton onClick={handleEditClick} />
                <ListenButton text={chat.content} />
                <DeleteButton onClick={handleDeleteClick} />
              </Container>

              <Link
                to={{
                  pathname: "/chat",
                }}
                state={{ 
                  messages: parseMessages(chat.content) 
                }}
                className="btn btn-primary mt-4 w-100 action-buttons"
              >
                Continue Chat
              </Link>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ChatDetails;
