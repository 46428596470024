import React from "react";
import UserExams from "./UserExams";
import Breadcrumb from "../../reusable-components/Breadcrumb"; 
import { Container } from "react-bootstrap";

const AllExams = () => {
  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Exam', path: '/exam', active: false },
    { label: 'Exams', path: '/exams', active: true },
  ];

  return (
    <Container>
      <Breadcrumb items={breadcrumbItems} /> 
      <UserExams displayAll={true} />
    </Container>
  );
};

export default AllExams;
