import { Link } from "react-router-dom";
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import { toast } from 'react-toastify';
import AuthContext from "../../utils/AuthContext";
import Breadcrumb from "../../reusable-components/Breadcrumb";

const TalkFeature = () => {
    const { user } = useContext(AuthContext);
    const [transcript, setTranscript] = useState("");
    const [isListening, setIsListening] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false); 
    const [lottieKey, setLottieKey] = useState(0); // Key for remounting the Lottie component
    const lottieRef = useRef(null); 
    const stopLottieRef = useRef(null); 
    const utteranceRef = useRef(null); 

    const configuration = new Configuration({
        apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    const handleListen = () => {
        console.log("User started talking");
        setIsListening(true);
        setIsProcessing(true);
        recognition.start();

        recognition.onresult = (event) => {
            const speechToText = event.results[0][0].transcript;
            console.log("User stopped talking, transcript:", speechToText);
            setTranscript(speechToText);
            setIsListening(false);
            handleSubmit(speechToText);
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
            setIsProcessing(false);
            toast.error("Speech recognition failed, please try again.");
            resetLottie(); 
        };

        recognition.onend = () => {
            console.log("Speech recognition ended");
            setIsListening(false);
        };
    };

    const handleSubmit = async (spokenMessage) => {
        console.log("AI started processing the request");
        try {
            const response = await openai.createChatCompletion({
                model: "gpt-4o-mini",
                messages: [{ role: 'user', content: spokenMessage }],
            });

            const assistantMessage = response.data.choices[0].message.content;
            console.log("AI responded with:", assistantMessage);
            speakResponse(assistantMessage);
        } catch (error) {
            console.error("Error with OpenAI API request:", error);
            toast.error("Failed to get response, please try again.");
            setIsProcessing(false); 
            resetLottie(); 
        }
    };

    const speakResponse = (text) => {
        console.log("AI started speaking the response");
        const utterance = new SpeechSynthesisUtterance(text);
        utteranceRef.current = utterance;
        utterance.onend = () => {
            console.log("AI finished speaking");
            setIsProcessing(false); 
            resetLottie(); 
        };
        window.speechSynthesis.speak(utterance);
    };

    const handleStop = () => {
        console.log("User stopped the AI from speaking");
        if (utteranceRef.current) {
            window.speechSynthesis.cancel();
            setIsProcessing(false);
            resetLottie(); 
        }
    };

    const resetLottie = () => {
        console.log("Resetting Lottie animation");
        setIsProcessing(false);
        setLottieKey(prevKey => prevKey + 1); // Remount the Lottie component by changing the key
    };

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Talk', path: '/talk', active: true },
    ];

    useEffect(() => {
        const lottiePlayer = lottieRef.current;

        const loadLottiePlayer = () => {
            const lottieScript = document.createElement("script");
            lottieScript.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
            lottieScript.type = "module";
            document.body.appendChild(lottieScript);
        };

        loadLottiePlayer();

        if (lottiePlayer) {
            lottiePlayer.addEventListener("click", handleListen);
        }

        return () => {
            if (lottiePlayer) {
                lottiePlayer.removeEventListener("click", handleListen);
            }
        };
    }, [lottieKey]); // Re-run the effect when lottieKey changes to ensure the listener is attached

    return (
        <div style={{ paddingBottom: "60px", paddingTop: "40px" }}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center chat-talk">
                    <Breadcrumb items={breadcrumbItems} />
                    <Link to="/talks" className="display-link" style={{ marginLeft: "auto" }}>
                        All Talks
                    </Link>
                </div>
                <h3 style={{ textAlign: "left", paddingLeft: "20px", paddingTop: "30px" }}>Interactive Talk Feature</h3>
                <p style={{ textAlign: "left", paddingLeft: "20px", paddingBottom: "20px" }}>
                    Speak directly to our AI, delving into discussions that range from the mysteries of black holes to the nuances of literary classics.
                </p>

                <div className="row">
                    <div className="col-md-9">
                        <div className="talk-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
                            {!isProcessing && (
                                <dotlottie-player
                                    key={lottieKey} // Key to trigger remount
                                    ref={lottieRef}
                                    src="https://lottie.host/999c22c7-5fbe-4d67-8ed9-9c6d1752dac4/Ed1gi8R33E.json"
                                    background="transparent"
                                    speed="1"
                                    style={{ width: '300px', height: '300px', cursor: 'pointer' }}
                                    loop
                                    autoplay
                                ></dotlottie-player>
                            )}
                            {isProcessing && (
                                <dotlottie-player
                                    ref={stopLottieRef}
                                    src="https://lottie.host/c9c1c5ea-8f53-400f-afbe-2c89eb9582c8/qAHqpQMCdL.json"
                                    background="transparent"
                                    speed="1"
                                    style={{ width: '300px', height: '300px', cursor: 'pointer', marginTop: '20px' }}
                                    loop
                                    autoplay
                                    onClick={handleStop}
                                ></dotlottie-player>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TalkFeature;
