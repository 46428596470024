import { useState, useEffect, useContext } from "react";
import { Container, Form, Button, FormGroup, FormControl, Row, Col, Image, Card } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookie } from "../utils/CookieHelper";
import jwt_decode from "jwt-decode";
import AuthContext from "../utils/AuthContext";

const loadGoogleApi = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { setAuthTokens, setUser } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length > 0) {
      const csrfToken = getCookie("csrftoken");

      const response = await fetch("https://blog.doctrina.ai/members/password-reset/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ email }),
      });

      if (response.status === 200) {
        toast.success("An email has been sent to reset your password");
        navigate("/login");
      } else {
        toast.error("Error sending the email");
      }
    }
  };

  const handleGoogleSignIn = async (response) => {
    const token = response.credential;
    const apiResponse = await fetch("https://blog.doctrina.ai/members/google-login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ token })
    });
    const data = await apiResponse.json();
    if (apiResponse.ok) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      window.scrollTo(0, 0); 
      navigate("/");
    } else {
      toast.error(data.error || "Google Sign-In failed");
    }
  };

  useEffect(() => {
    loadGoogleApi().then(() => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: '1098289877563-s6vrn3li4isjr6j8hfeqtafidcfrbdrg.apps.googleusercontent.com',
          callback: handleGoogleSignIn
        });
        window.google.accounts.id.renderButton(
          document.getElementById('google-signin'),
          { theme: 'outline', size: 'large' }
        );
      }
    }).catch((error) => {
      console.error("Failed to load Google API", error);
    });
  }, []);

  return (
    <Container fluid style={{ backgroundColor: "#fff", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Row className="justify-content-md-center pt-3">
        <Col md={6} className="text-center">
          <Link to="https://doctrina.ai" target="_blank" rel="noopener noreferrer">
            <Image className="logo mt-5 mb-5 login-logo" loading="lazy" fluid src="https://i.ibb.co/C7ykCPj/logo-white.png" />
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-3">Forgot Password</h1>
              <h6 className="text-center mb-3">Enter your email to reset your password</h6>
              <Form onSubmit={handleSubmit}>
                <FormGroup controlId="formBasicEmail" className="mt-5 register-form">
                  <FormControl
                    type="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </FormGroup>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-50 mt-5 login-button"
                >
                  Submit
                </Button>
              </Form>
              <h6 className="text-center mt-5 pb-3">or log in with email</h6>
              <Container id="google-signin" className="d-flex justify-content-center mt-2 pb-3"></Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3 pb-3">
        <Col md={6} className="text-center">
        <ul className="list-unstyled" style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <li style={{ paddingRight: '10px' }}>
            <Link to="https://doctrina.ai/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li style={{ paddingLeft: '10px' }}>
            <Link to="https://doctrina.ai/terms.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>
          </li>
        </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
