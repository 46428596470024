import React from "react";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { Container, Row, Col } from "react-bootstrap";

const SocialFooter = () => {
  return (
    <div className="social-footer mt-5 text-center">
      <Container className="pt-2">
        <Row className="justify-content-center">
          <Col sm="auto" className="d-flex justify-content-center pb-4">
            {/* <a href="https://x.com/DoctrinaAI" target="_blank" rel="noopener noreferrer" className="social-icon mx-3">
              <FaXTwitter size={36} />
            </a> */}
            <a href="https://www.instagram.com/doctrina.ai/" target="_blank" rel="noopener noreferrer" className="social-icon mx-3">
              <FaInstagram size={50} />
            </a>
          </Col>
        </Row>
        {/* <Row className="justify-content-center pt-3 pb-3">
          <Col sm="auto">
            <a href="#" className="fw-bold" style={{color: "#FF9933 !important"}}>
              Learn More
            </a>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default SocialFooter;
