import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link, useNavigate } from "react-router-dom";
import { IoEnterOutline } from "react-icons/io5";

const UserExams = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [exams, setExams] = useState([]);
  const axiosInstance = useAxios();
  const navigate = useNavigate();
const [isFetched, setIsFetched] = useState(false); 

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axiosInstance.get(`/cases/exams/?user_id=${user.user_id}`);
        setExams(response.data);
        setIsFetched(true); 
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };
  
    if (user && !isFetched) {
      fetchExams();
    }
  }, [user, axiosInstance, isFetched]); 

  const displayExams = displayAll ? exams : exams.slice(0, 5);

  const renderGrid = (exam) => (
    <Col key={exam.id} sm={12} md={6} lg={4} className="my-2 latest-feature-item">
      <Card>
        <Card.Body>
          <Link to={`/exam/${exam.id}`} className="card-title">
            {exam.book}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderList = (exam) => (
    <Card key={exam.id} className="my-2 latest-feature-item">
      <Card.Body>
        <Link to={`/exam/${exam.id}`} className="card-title">
          {exam.book}
        </Link>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="latest-feature-container">
      <h2>{displayAll ? "All Exams" : "Latest Exams"}</h2>
      {displayAll ? (
        <Row>{displayExams.map(renderGrid)}</Row>
      ) : (
        displayExams.map(renderList)
      )}
      {!displayAll && (
        <Container className="all-items-link mt-4 mb-5 go-container latest-feature-item" onClick={() => navigate("/exams")} style={{ cursor: 'pointer' }}>
          All Exams <IoEnterOutline size={24} /> 
        </Container>
      )}
    </Container>
  );
};

export default UserExams;
