import React from "react";
import { Button } from "react-bootstrap";
import { FaPencil } from "react-icons/fa6";

const EditButton = ({ onClick }) => {
  return (
    <Button
      className="d-flex align-items-center mb-3 mt-3 action-buttons"
      onClick={onClick}
    >
      <FaPencil className="me-2" /> Edit
    </Button>
  );
};

export default EditButton;
