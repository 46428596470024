import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from './Home';
import AppGenerator from './features/essay/EssayGenerator';
import ExamQuestions from './features/exam/ExamGenerator';
import { AuthProvider } from "./utils/AuthContext";
import Login from "./authentication/LoginPage";
import Register from "./authentication/RegisterPage";
import EssayDetails from "./features/essay/EssayDetails";
import NotesDetail from './features/notes/NotesDetail';
import AllNotes from './features/notes/AllNotes';
import { ToastContainer } from 'react-toastify';
import AllEssays from './features/essay/AllEssays';
import AllExams from './features/exam/AllExams';
import ExamDetail from './features/exam/ExamDetails';
import ChatApp from './features/chatDoctrina/ChatApp';
import Layout from './reusable-components/Layout';
import ClassNotes from './features/notes/ClassNotes';
import QuizApp from './features/quiz/QuizApp';
import QuizDetails from './features/quiz/QuizDetails'; 
import UserProfile from './reusable-components/UserProfile';
import SummaryGenerator from './features/summaries/SummaryGenerator';
import SummaryDetails from './features/summaries/SummaryDetail';
import AllSummaries from './features/summaries/AllSummaries';
import ProtectedRoute from './utils/ProtectedRoute'; 
import ChatDetails from './features/chatDoctrina/ChatDetails';
import AllChats from './features/chatDoctrina/AllChats';
import ForgotPassword from './authentication/ForgotPassword';
import ThankYou from './payment/ThankYou';
import ChangePassword from './authentication/ChangePassword';
import AllQuizzes from './features/quiz/AllQuizzes'; 
import TalkFeature from './features/talkDoctrina/Talk';
import ScrollToTop from './reusable-components/ScrollToTop';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quiz" element={<QuizApp />} />
            <Route path="/quiz/:id" element={<QuizDetails />} /> 
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/reset-password/:uidb64/:token" element={<ChangePassword />} />
            <Route path="/quizzes" element={<AllQuizzes />} /> 
            <Route path="/essay" element={<AppGenerator />} />
            <Route path="/essay/:id" element={<EssayDetails />} />
            <Route path="/essays" element={<AllEssays />} />


            <Route path="/note" element={<ProtectedRoute><ClassNotes /></ProtectedRoute>} />
            <Route path="/note/:id" element={<ProtectedRoute><NotesDetail /></ProtectedRoute>} />
            <Route path="/notes" element={<ProtectedRoute><AllNotes /></ProtectedRoute>} />
            <Route path="/summary" element={<ProtectedRoute><SummaryGenerator /></ProtectedRoute>} />
            <Route path="/summary/:id" element={<ProtectedRoute><SummaryDetails /></ProtectedRoute>} />
            <Route path="/summaries" element={<ProtectedRoute><AllSummaries /></ProtectedRoute>} />
            <Route path="/exam" element={<ProtectedRoute><ExamQuestions /></ProtectedRoute>} />
            <Route path="/exams" element={<ProtectedRoute><AllExams /></ProtectedRoute>} />
            <Route path="/exam/:id" element={<ProtectedRoute><ExamDetail /></ProtectedRoute>} />
            <Route path="/chat" element={<ProtectedRoute><ChatApp /></ProtectedRoute>} />
            <Route path="/chat/:id" element={<ProtectedRoute><ChatDetails /></ProtectedRoute>} />
            <Route path="/chats" element={<ProtectedRoute><AllChats /></ProtectedRoute>} />
            <Route path="/talk" element={<ProtectedRoute><TalkFeature /></ProtectedRoute>} />
          </Routes>
        </Layout>
        <ToastContainer position="bottom-left" toastClassName="light-toast" />
      </Router>
    </AuthProvider>
  );
}

export default App;
