import React from "react";
import UserChats from "./UserChats";
import Breadcrumb from "../../reusable-components/Breadcrumb";

const AllChats = () => {
  const breadcrumbItems = [
      { label: 'Home', path: '/', active: false },
      { label: 'Chat', path: '/chat', active: false },
      { label: 'Chats', path: '/chats', active: true },
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      <UserChats displayAll={true} />
    </div>
  );
};

export default AllChats;
