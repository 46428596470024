import React from "react";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <dotlottie-player
        src="https://lottie.host/524b818b-5144-42cb-b1be-e603d7290033/aOPi3pLMta.json"
        background="transparent"
        speed="1"
        style={{ width: "100px", height: "100px" }} 
        loop
        autoplay
      ></dotlottie-player>
    </div>
  );
};

export default LoadingSpinner;
