import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/UseAxios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import DownloadFile from "../../reusable-components/DownloadFile";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import LoadingSpinner from "../../reusable-components/LoadingSpinner";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import EditButton from "../../reusable-components/EditButton";
import SaveButton from "../../reusable-components/SaveButton";

const SummaryDetails = () => {
  const [summary, setSummary] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedSummary, setEditedSummary] = useState({});
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await axiosInstance.get(`/cases/summaries/${id}/`);
        if (response.status === 403) {  
          navigate("/summaries");  
        } else {
          setSummary(response.data);
          setIsFetched(true); // Set to true after summary is fetched
        }
      } catch (error) {
        console.error("Error fetching summary:", error);
        if (error.response && error.response.status === 403) {
          navigate("/summaries");
        } else {
          navigate("/summaries");
        }
      }
    };
  
    // Only fetch summary if it hasn't been fetched yet
    if (!isFetched) {
      fetchSummary();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const handleEditClick = () => {
    setEditing(true);
    setEditedSummary(summary);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedSummary((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/cases/summaries/${id}/`, editedSummary);
      setSummary(editedSummary);
      setIsFetched(false); // Reset to allow refetching if needed
      toast.success("Summary updated successfully");
      setEditing(false);
    } catch (error) {
      toast.error("Failed to update summary");
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/summaries/${id}/`);
      toast.success("Summary deleted successfully");
      navigate("/summary");
    } catch (error) {
      console.error("Error deleting summary:", error);
    }
  };

  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Summary', path: '/summary', active: false },
    { label: 'Summaries', path: '/summaries', active: false },
    { label: summary?.book || 'Summary Details', path: `/summary/${id}`, active: true },
  ];

  return (
    <Container className="pt-2 left-align">
      <Breadcrumb items={breadcrumbItems} />
      {summary && (
        <>
          {editing ? (
            <Form className="pt-3">
              <Form.Group className="single-item-form">
                <Form.Control
                  type="text"
                  name="book"
                  value={editedSummary.book}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="container">
                <ReactQuill 
                  value={editedSummary.content}
                  onChange={(content, delta, source, editor) => {
                    const value = editor.getHTML();
                    setEditedSummary((prevState) => ({
                      ...prevState,
                      content: value,
                    }));
                  }}
                />
              </Form.Group>
              <Container className="sticky-buttons">
                <SaveButton onClick={handleSaveClick} />
                <CancelButton onClick={() => setEditing(false)} />
              </Container>
            </Form>
          ) : (
            <>
              <h2 className="single-item-form">{summary.book}</h2>
              <Container className="response-saved"
                style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{ __html: summary.content }}
              />
              <Container className="sticky-buttons">
              <ListenButton text={summary.content} />
                {/* <DownloadFile content={summary.content} fileName={`${summary.book}.pdf`} /> */}
                <EditButton onClick={handleEditClick} />
                <DeleteButton onClick={handleDeleteClick} />
              </Container>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default SummaryDetails;
