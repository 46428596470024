import React, { useState, useEffect } from "react";
import { Container, Form, ListGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import useAxios from "../../utils/UseAxios";
import useToast from "../../reusable-components/UseToast";
import LoadingSpinner from "../../reusable-components/LoadingSpinner";
import ListenButton from "../../reusable-components/ListenButton";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import CancelButton from "../../reusable-components/CancelButton";
import DeleteButton from "../../reusable-components/DeleteButton";
import DownloadFile from "../../reusable-components/DownloadFile";
import SaveButton from "../../reusable-components/SaveButton";

const QuizDetails = () => {
  const [file, setFile] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedFile, setEditedFile] = useState({});
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const toast = useToast();
  const [isFetched, setIsFetched] = useState(false); // New state to track fetch status


  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axiosInstance.get(`/cases/quizzes/${id}/`);
        if (response.status === 403) {
          navigate("/quizzes");
        } else {
          setFile(response.data);
          setIsFetched(true); // Set to true after file is fetched
        }
      } catch (error) {
        console.error("Error fetching file:", error);
        if (error.response && error.response.status === 403) {
          navigate("/quizzes");
        } else {
          navigate("/quizzes");
        }
      }
    };
  
    // Only fetch file if it hasn't been fetched yet
    if (!isFetched) {
      fetchFile();
    }
  }, [axiosInstance, id, isFetched]); // Add isFetched to the dependency array
  

  const handleEditClick = () => {
    setEditing(true);
    setEditedFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      // Validate and sanitize JSON content
      const quizContent = JSON.parse(editedFile.content);
      
      // Validate that each question and options are properly structured
      quizContent.forEach(question => {
        if (!question.text || !Array.isArray(question.options) || !question.correctOption) {
          throw new Error("Invalid question structure");
        }
      });
  
      await axiosInstance.put(`/cases/quizzes/${id}/`, {
        ...editedFile,
        content: JSON.stringify(quizContent),
      });
  
      setFile(editedFile);
      setEditing(false);
      setIsFetched(false); // Reset to allow refetching if needed
      toast.success("Quiz saved successfully");
    } catch (error) {
      toast.error("Error updating quiz");
    }
  };
  

  const handleDeleteClick = async () => {
    try {
      await axiosInstance.delete(`/cases/quizzes/${id}/`);
      setDeleted(true);
      toast.success("Quiz deleted successfully");
      navigate("/quizzes");
    } catch (error) {
      toast.error("Error deleting quiz");
    }
  };

  if (deleted) {
    return null;
  }

  if (!file) {
    return <LoadingSpinner />;
  }

  const breadcrumbItems = [
    { label: "Home", path: "/", active: false },
    { label: "Quiz", path: "/quiz", active: false },
    { label: "Quizzes", path: "/quizzes", active: false },
    { label: file?.topic_or_file_name || "Quiz Details", path: `/quiz/${id}`, active: true },
  ];

  const quizContent = JSON.parse(file.content || "[]");

  return (
    <Container className="left-align">
      <Breadcrumb items={breadcrumbItems} />
      <Form className="pt-3 single-item-form">
        <Form.Group>
          {editing ? (
            <Form.Control
              name="topic_or_file_name"
              type="text"
              value={editedFile.topic_or_file_name}
              onChange={handleInputChange}
            />
          ) : (
            <h2 className="pb-3">{file.topic_or_file_name}</h2>
          )}
        </Form.Group>
        <Form.Group>
          {editing ? (
            <ReactQuill
            name="content"
            value={editedFile.content || ""}
            onChange={(value) => 
              setEditedFile((prevState) => ({
                ...prevState,
                content: value,
              }))
            }
          />
          
          ) : (
            <div className="content-wrapper">
              <ListGroup>
                {quizContent.map((question, index) => (
                  <ListGroup.Item key={index}>
                    <strong>{`Q${index + 1}: ${question.text}`}</strong>
                    <ul className="quiz-options">
                      {question.options.map((option, optIndex) => (
                        <li key={optIndex} className={question.correctOption === option ? "correct-option" : ""}>
                          {option}
                        </li>
                      ))}
                    </ul>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Form.Group>
        {editing ? (
          <Container className="sticky-buttons">
            <SaveButton onClick={handleSaveClick} />
            <CancelButton onClick={() => setEditing(false)} />
          </Container>
        ) : (
          <Container className="sticky-buttons">
            <DownloadFile content={file.content} fileName={`Quiz.pdf`} />
            <ListenButton text={file.content} />
            <DeleteButton onClick={handleDeleteClick} />
          </Container>
        )}
      </Form>
    </Container>
  );
};

export default QuizDetails;
