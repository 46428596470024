import React from "react";
import UserSummaries from "./UserSummaries";
import Breadcrumb from "../../reusable-components/Breadcrumb";
import { Container } from "react-bootstrap";

const AllSummaries = () => {
  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Summary', path: '/summary', active: false },
    { label: 'Summaries', path: '/summaries', active: true },
  ];

  return (
    <Container>
      <Breadcrumb items={breadcrumbItems} />
      <UserSummaries displayAll={true} />
    </Container>
  );
};

export default AllSummaries;
