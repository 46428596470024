import React, { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import AuthContext from "../../utils/AuthContext";
import useAxios from "../../utils/UseAxios";
import { Link, useNavigate } from "react-router-dom";
import { IoEnterOutline } from "react-icons/io5"; 

const UserNotes = ({ displayAll }) => {
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [isFetched, setIsFetched] = useState(false); 
    const axiosInstance = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/cases/files/?user_id=${user.user_id}`);
        setFiles(response.data);
        setIsFetched(true); 
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };
  
    if (user && !isFetched) {
      fetchFiles();
    }
  }, [user, axiosInstance, isFetched]); 
  

  const displayFiles = displayAll ? files : files.slice(0, 5);

  const renderGrid = (file) => (
    <Col key={file.id} sm={12} md={6} lg={4} className="my-2">
      <Card>
        <Card.Body>
          <Link to={`/note/${file.id}`} className="card-title">
            {file.name}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderList = (file) => (
    <Card key={file.id} className="my-2">
      <Card.Body>
        <Link to={`/note/${file.id}`} className="card-title">
          {file.name}
        </Link>
      </Card.Body>
    </Card>
  );

  return (
    <Container>
      <h2 className="my-4">{displayAll ? "All Notes" : "Latest Notes"}</h2>
      {displayAll ? (
        <Row>{displayFiles.map(renderGrid)}</Row>
      ) : (
        displayFiles.map(renderList)
      )}
      {!displayAll && (
        <Container
          className="all-items-link mt-4 mb-5 go-container latest-feature-item"
          onClick={() => navigate("/notes")}
          style={{ cursor: 'pointer' }}
        >
          All Notes <IoEnterOutline size={24} />
        </Container>
      )}
    </Container>
  );
};

export default UserNotes;
