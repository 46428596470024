import React from 'react';
import { Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoEnterOutline } from "react-icons/io5";

const Display = ({ header, description, icon, link, locked }) => {
  return (
    <div className={`feature-card ${locked ? 'locked' : ''}`}>
      <Card className="feature-card-inner">
        <Card.Header className="feature-card-header">
          <div className="feature-icon">{icon}</div>
          <h2 className="feature-title">{header}</h2>
        </Card.Header>
        <Card.Body className="feature-card-body">
          <p className="feature-description">{description}</p>
          <LinkContainer to={link}>
            <div className="cta-container">
              <span className="cta-text">
                {locked ? "Go Premium" : `Enter ${header}`}
              </span>
              <IoEnterOutline className="cta-icon" />
            </div>
          </LinkContainer>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Display;
