import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa6";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DownloadFile = ({ content, fileName }) => {
  const downloadPdf = () => {
    const docDefinition = {
      content: htmlToPdfmake(content),
    };
    pdfMake.createPdf(docDefinition).download(fileName);
  };

  return (
    <Button
      className="action-buttons d-flex align-items-center mb-3 mt-3"
      onClick={downloadPdf}
    >
      <FaDownload className="me-2" /> Download
    </Button>
  );
};

export default DownloadFile;
