import React from "react";
import { Card } from "react-bootstrap";

const ResultCard = ({ heading, content, onChange }) => {
    return (
        <Card>
            <Card.Header>
                <h3>{heading}</h3>
            </Card.Header>
            <Card.Body>
                <textarea value={content} onChange={onChange} style={{ width: "100%", height: "300px", fontSize: "16px" }}/>
            </Card.Body>
        </Card>
    );
};

export default ResultCard;
