import React from "react";
import UserQuizzes from "./UserQuizzes";
import Breadcrumb from "../../reusable-components/Breadcrumb"; // Import the Breadcrumb component
import { Container } from "react-bootstrap";

const AllQuizzes = () => {
  const breadcrumbItems = [
    { label: 'Home', path: '/', active: false },
    { label: 'Quiz', path: '/quiz', active: false },
    { label: 'Quizzes', path: '/quizzes', active: true },
  ];

  return (
    <Container>
      <Breadcrumb items={breadcrumbItems} /> {/* Add Breadcrumb here */}
      <UserQuizzes displayAll={true} />
    </Container>
  );
};

export default AllQuizzes;
