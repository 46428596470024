import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="text-center fact p-5">
            <span>Doctrina AI may occasionally produce inaccurate information about people, places, or facts.</span>
            </div>
        );
    }
}

export default Footer;
