import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';

const DropdownSelect = ({ options, value, onChange, ...props }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className="custom-select">
            <FormControl     className="dropdown-select"

                as="select" 
                value={value}
                onChange={onChange}
                onFocus={() => setIsDropdownOpen(true)}
                onBlur={() => setIsDropdownOpen(false)}
                data-open={isDropdownOpen}
                {...props}
            >
                {options.map(option => (
                    <option 
                        key={option.value} 
                        value={option.value} 
                        disabled={option.disabled}
                        className={option.disabled ? 'disabled-option' : ''}
                    >
                        {option.label}
                    </option>
                ))}
            </FormControl>
        </div>
    );
};

export default DropdownSelect;
